import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DatePicker } from "antd";
import { Link } from "react-router-dom";
import {
  BsPerson,
  BsCalendar4Week,
  BsCardChecklist,
  BsBoxSeam,
} from "react-icons/bs";
import ReactApexChart from "react-apexcharts";
import ReportDashboardServices from "../../services/ReportDashboardServices";
import ReportsServices from "../../services/ReportsServices";

function ReportDashboard() {
  const { RangePicker } = DatePicker;
  const currentDate = new Date();
  const [startDateClick, setStartDateClick] = useState(
    new Date(currentDate.getFullYear(), 0, 1).toISOString()
    //moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDateClick, setEndDateClick] = useState(
    new Date(currentDate.getFullYear(), 11, 31).toISOString()
  );
  const [percentageData, setPercentageData] = useState([]);
  const [symtomReportData, setSymtomReportData] = useState([]);
  const [cycleReportData, setCycleReportData] = useState([]);
  const [orderReportData, setOrderReportData] = useState([]);
  const [inActive, setInActive] = useState([]);

  const [optionSysmtyoms, setOptionSystoms] = useState();
  const [optionCycle, setOptionCycle] = useState();
  const [optionOrder, setOptionOrder] = useState();

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  const getReportsData = () => {
    ReportDashboardServices.allPercentage()
      .then((res) => {
        setPercentageData(res);
      })
      .catch((err) => {
        console.log("Error on the all percentaage api");
      });

    ReportDashboardServices.inactvepercentage()
      .then((ress) => {
        setInActive(ress);
      })
      .catch((err) => {
        console.log("Error on the inactive percentaage api");
      });
  };

  const getCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.symtponReportDash(startDateClick, endDateClick)
      .then((res) => {
        setOptionSystoms({
          chart: {
            id: "line-chart",
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 2000,
              },
            },
          },
          xaxis: {
            title: { text: "Trend of customers marking symptoms" },
            categories: res[1].month,
          },
          colors: ["#6c5c8d"],
        });
        setSymtomReportData(res);
      })
      .catch((err) => console.log(err.message));
  };

  const getCycleCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.cycleReportDash(startDateClick, endDateClick)
      .then((res) => {
        setOptionCycle({
          chart: {
            id: "line-chart",
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 2000,
              },
            },
          },
          xaxis: {
            title: {
              text: "Trend of customers using period tracking features",
            },
            categories: res[1].month,
          },
          colors: ["#6c5c8d"],
        });
        setCycleReportData(res);
      })
      .catch((err) => console.log(err.message));
  };

  const getPerOrderCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.percentOrderReportDash(startDateClick, endDateClick)
      .then((res) => {
        setOptionOrder({
          chart: {
            id: "line-chart",
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 2000,
              },
            },
          },
          xaxis: {
            title: { text: "Trend of customers placing orders" },
            categories: res[1].month,
          },
          colors: ["#6c5c8d"],
        });
        setOrderReportData(res);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    getReportsData();
  }, []);

  useEffect(() => {
    getCurrentReport(startDateClick, endDateClick);
    getCycleCurrentReport(startDateClick, endDateClick);
    getPerOrderCurrentReport(startDateClick, endDateClick);
  }, [endDateClick]);

  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Report Dashboard</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports Dash</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsBoxSeam />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {percentageData?.customersWithOrders}%
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            of total customers placed orders
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsCalendar4Week />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {percentageData?.customerwithSymtons}%
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            of total customers marked symptoms
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsCardChecklist />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {percentageData?.customerwithcyclePercentage}%
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            of total customers are using period tracking
                            features
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsPerson />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {inActive?.inactiveusers}%
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            of total customers are inactive
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className=" mb-3 text-end">
                <RangePicker
                  allowClear="true"
                  onCalendarChange={handleCalendarChange}
                />
                &nbsp;
              </div>
              <div className="col-md-4">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    {optionOrder && (
                      <ReactApexChart
                        type="line"
                        height={300}
                        series={orderReportData}
                        options={optionOrder}
                      ></ReactApexChart>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    {optionSysmtyoms && (
                      <ReactApexChart
                        type="line"
                        height={300}
                        series={symtomReportData}
                        options={optionSysmtyoms}
                      ></ReactApexChart>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    {optionCycle && (
                      <ReactApexChart
                        type="line"
                        height={300}
                        series={cycleReportData}
                        options={optionCycle}
                      ></ReactApexChart>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportDashboard;
