import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DataFunction from "../../../Functions/AllFunctions";
import CouponServices from "../../../services/CouponServices";
import { DatePicker } from "antd";
import moment from "moment";
import TableLoader from "../../../Reuseable/TableLoader";
import Select from "react-select";
import CustomerServices from "../../../services/CustomerServices";

const ProductCoupons = () => {
  const [allCoupons, setAllCoupons] = useState([]);
  const [editId, setEditId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [couponCode, setCouponCode] = useState("");
  const [endTime, setEndTime] = useState(moment());
  const [discountValue, setDiscountValue] = useState("");
  const [minimumAmount, setMinimumAmount] = useState("");
  const [maximumUsage, setMaximumUsage] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("percentage");

  // const [allUsers, setAllUsers] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);

  const SelectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#c6a1f8" : null,
        color: isFocused ? "#fff" : "#333333",

        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c6a1f8"
              : "#c6a1f8"
            : undefined,
        },
      };
    },
  };

  const getAllUsers = useCallback(() => {
    const allUsersData = [];

    CustomerServices.getAllCustomers().then((res) => {
      for (let index = 0; index < res?.length; index++) {
        const element = res[index];
        const obj = {
          label: element.full_name,
          value: element.customerId,
        };
        allUsersData.push(obj);
      }
    });

    // setAllUsers(allUsersData);
  }, []);

  // Form State
  const getData = useCallback(() => {
    CouponServices.GetAllCoupon()
      .then((res) => {
        setAllCoupons(res);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
    getAllUsers();
  }, []);

  const createCode = (e) => {
    var text = "";
    var possible = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    setCouponCode(text);
    // return text;
  };

  const CreateCoupone = (e) => {
    e.preventDefault();

    const data = {
      couponCode: couponCode,
      endTime: endTime,
      discountValue: discountValue,
      minimumAmount: minimumAmount,
      maximumUsage: maximumUsage,
      discountIn: selectedDiscount,
      // users: selectedUsers,
    };
    CouponServices.AddCoupon(data)
      .then((res) => {
        const domEndTime = document.getElementById("endtimedate");
        domEndTime.value = "";
        setCouponCode("");
        setEndTime(null);
        setDiscountValue("");
        setMinimumAmount("");
        setMaximumUsage("");
        setSelectedDiscount("percentage");
        getData();
      })
      .catch((error) => {
        setCouponCode("");
        setEndTime("");
        setDiscountValue("");
        setMinimumAmount("");
        setMaximumUsage("");
        setSelectedDiscount("percentage");
        getData();
      });
  };

  const deleteCoupon = (e, id) => {
    e.preventDefault();
    CouponServices.DeleteCoupon(id)
      .then((res) => {
        getData();
      })
      .catch((error) => console.log(error.message));
  };

  const editCategory = (e, id) => {
    e.preventDefault();
    const data = {
      couponCode: couponCode,
      endTime: endTime,
      discountValue: discountValue,
      minimumAmount: minimumAmount,
      maximumUsage: maximumUsage,
      discountIn: selectedDiscount,
    };
    CouponServices.UpdateCoupon(id, data)
      .then((res) => {
        setCouponCode("");
        setEndTime(null);
        setDiscountValue("");
        setMinimumAmount("");
        setMaximumUsage("");
        setSelectedDiscount("percentage");
        setIsEditing(false);
        getData();
      })
      .catch((error) => {
        setCouponCode("");
        setEndTime(null);
        setDiscountValue("");
        setMinimumAmount("");
        setMaximumUsage("");
        setSelectedDiscount("percentage");
        setIsEditing(false);
        getData();
      });
  };

  const handelEditClick = (e, item) => {
    setIsEditing(true);
    setEditId(item?._id);
    setCouponCode(item?.couponCode);
    setEndTime(moment(item?.endTime).format("YYYY-MM-DD"));
    setDiscountValue(item?.discountValue);
    setMinimumAmount(item?.minimumAmount);
    setMaximumUsage(item?.maximumUsage);
    setSelectedDiscount(item?.discountIn);

    // const da = item.users.filter((els) =>
    //   allUsers.filter((ess) => els.value === ess.value)
    // );

    // setSelectedUsers(da);
  };

  const changeStatus = (id) => {
    CouponServices.StatusChange(id).then((res) => getData());
  };
  const changeMulti = (id) => {
    CouponServices.MultiChange(id).then((res) => getData());
  };

  return (
    <>
      <Helmet>
        <title>Coupons - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Coupons</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Coupons</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={
                        isEditing
                          ? (e) => editCategory(e, editId)
                          : (e) => CreateCoupone(e)
                      }
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Coupon Code
                        </label>
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            required
                            style={{ marginRight: "20px" }}
                            className="form-control"
                            type="text"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                          />
                          <span onClick={(e) => createCode(e)}>
                            <i
                              className="mdi mdi-wallet-giftcard iconsize"
                              title="Auto-Generate Coupon"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="endtimedate"
                          className="col-md-12 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-md-12">
                          <input
                            id="endtimedate"
                            type="date"
                            className="form-control"
                            onChange={(e) => setEndTime(e.target.value)}
                            value={endTime}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Discount In
                        </label>
                        <div className="col-md-12">
                          <div>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="formRadios"
                                id="percentage"
                                onClick={() =>
                                  setSelectedDiscount("percentage")
                                }
                                value={selectedDiscount}
                                checked={selectedDiscount === "percentage"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="percentage"
                              >
                                Percentage
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="formRadios"
                                id="fixedvalue"
                                onClick={() =>
                                  setSelectedDiscount("fixedvalue")
                                }
                                value={selectedDiscount}
                                checked={
                                  selectedDiscount === "fixedvalue"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="fixedvalue"
                              >
                                Fixed Value
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Discount Value (in{" "}
                          {selectedDiscount === "percentage"
                            ? "Percentage"
                            : "Fixed Value"}
                          )
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="number"
                            value={discountValue}
                            min={selectedDiscount === "percentage" ? 0 : null}
                            max={selectedDiscount === "percentage" ? 100 : null}
                            onChange={(e) => setDiscountValue(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Maximum Usage
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="number"
                            min={0}
                            defaultValue={maximumUsage}
                            onChange={(e) => setMaximumUsage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Minimum Amount
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="number"
                            defaultValue={minimumAmount}
                            onChange={(e) => setMinimumAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="card">
                  <div className="card-body">
                    {allCoupons.length === null || allCoupons.length === 0 ? (
                      <TableLoader />
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>End Time</th>
                              <th>Max Usage</th>
                              <th>Min Amount</th>
                              <th>Coupons Used</th>
                              <th>Discount</th>
                              <th>Multi-Use</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {allCoupons &&
                              allCoupons?.map((el, index) => (
                                <tr key={index}>
                                  <td>{el?.couponCode}</td>
                                  <td>
                                    {moment(el?.endTime).format("MMM DD,YYYY")}
                                  </td>
                                  <td>{el?.maximumUsage}</td>
                                  <td>{el?.minimumAmount}</td>
                                  <td>{el?.totalUsed}</td>
                                  <td>
                                    {el?.discountIn === "percentage"
                                      ? `${el?.discountValue}%`
                                      : `Rs ${el?.discountValue}`}
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="form-check form-switch form-switch-md mb-3">
                                      <input
                                        onChange={() => changeMulti(el._id)}
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd"
                                        defaultChecked={el.multi}
                                      />
                                    </div>
                                  </td>
                                  <th>
                                    <div className="form-check form-switch form-switch-md mb-3">
                                      <input
                                        onChange={() => changeStatus(el._id)}
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd"
                                        defaultChecked={el.status}
                                      />
                                    </div>
                                  </th>
                                  <td>
                                    <i
                                      className="mdi mdi-trash-can-outline iconsize"
                                      onClick={(e) => deleteCoupon(e, el?._id)}
                                    />
                                    <i
                                      className="mdi mdi-pencil-box-outline iconsize"
                                      onClick={(e) => handelEditClick(e, el)}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCoupons;
