import React, { useState, useEffect } from "react";
import OrderServices from "../../../services/OrderServices";

function OrderStatus({ status, getData, item, setRejectionId }) {
  const [orderStatus, setOrderStatus] = useState("");

  useEffect(() => {
    setOrderStatus(status);
  }, []);

  const handelChange = (e) => {
    const value = e.target.value;
    setOrderStatus(value);
    if (value === "Cancelled") {
      document.querySelector("#oderCanvelModel").click();
    }
    setRejectionId(item?.orderId);

    OrderServices.statusUpdate(item._id, {
      status: e.target.value,
    }).then((resss) => {
      getData();
    });
  };

  return (
    <>
      <select
        className="form-select form-select-sm"
        value={orderStatus}
        onChange={(e) => handelChange(e)}
      >
        <option value="Pending">Pending</option>
        <option value="In Process">Processing</option>
        <option value="Delivered">Delivered</option>
        <option value="Cancelled">Cancelled</option>
        <option value="Returned">Returned</option>
      </select>
    </>
  );
}

export default OrderStatus;
