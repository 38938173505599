import requests from "./httpService";

const ProductServices = {
  getAllProducts() {
    return requests.get(`/products`);
  },
  addProducts(body) {
    return requests.post(`/products/add`, body);
  },
  deleteProduct(id) {
    return requests.delete(`/products/${id}`);
  },
  getProductById(id) {
    return requests.post(`/products/${id}`);
  },
  updateProduct(id, body) {
    return requests.put(`/products/${id}`, body);
  },
};

export default ProductServices;
