import { DatePicker } from "antd";
import axios from "axios";
import { CSVLink } from "react-csv";
import Select from "react-select";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../Reuseable/Pagination";
import OrderServices from "../../../services/OrderServices";
import { paginate } from "../../../utils/Paginate";
import OrderStatus from "./OrderStatus";
import OrderStatusDelivered from "./OrderStatusDelivered";
import { commaSperate } from "../../../Functions/Helper";
import TableLoader from "../../../Reuseable/TableLoader";
import RejectionService from "../../../services/RejectionService";
import CityServices from "../../../services/CityServices";
import { notifyError, notifySuccess } from "../../../utils/toast";

const SelectStyle = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#c6a1f8" : null,
      color: isFocused ? "#fff" : "#333333",

      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#c6a1f8"
            : "#c6a1f8"
          : undefined,
      },
    };
  },
};

const Orders = () => {
  const navigate = useNavigate();
  const [allOrders, setAllOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderdDataFormated, setOrderdDataFormated] = useState([]);
  const [orderdDataFiltert, setOrderdDataFiltert] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isOrderID, setOrderID] = useState(null);
  const [allRejections, setAllRejections] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [actionValue, setActionValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [startDateClick, setStartDateClick] = useState("");
  const [endDateClick, setEndDateClick] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const ordersData = paginate(allOrders, currentPage, pageSize);
  const [allCities, setAllCities] = useState([]);
  const [rejectionId, setRejectionId] = useState(null);
  const [isOrderLoading, setIsOrderLoading] = useState(false);

  console.log("startDate", startDate, "endDate", pageSize, "pageSize");

  const getData = () => {
    setIsLoading(true);
    setIsOrderLoading(true);
    OrderServices.getAllOrders()
      .then((res) => {
        setAllOrders(res);
        let formatedData = [];
        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          let object = {
            ...element,
            date: moment(element.createdAt).format("DD/MM/YYYY"),
          };
          formatedData.push(object);
        }
        setOrderdDataFormated(formatedData);

        setIsLoading(false);
        setIsOrderLoading(false);
      })
      .catch((err) => console.log(err.message));
    RejectionService.getRejection().then((ress) => {
      setAllRejections(ress);
    });
    CityServices.getAllCities()
      .then((res) => setAllCities(res))
      .catch((err) => console.log(err.message));
  };

  const updateAllSwiftStatus = (e) => {
    e.preventDefault();
    setIsOrderLoading(true);
    OrderServices.updateAllSwiftStatus()
      .then((res) => {
        setIsOrderLoading(false);
        notifySuccess("Swyft orders statuses updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onButtonClick = () => {
    console.log("Press");
    setStartDate(startDateClick);
    setEndDate(endDateClick);
    setPageSize(99999999);
  };

  const onDateClear = (value, dateString) => {
    if (dateString[0] === "" && dateString[1] === "") {
      setStartDate(dateString[0]);
      setEndDate(dateString[1]);
      setPageSize(10);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handelPageChange = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };
  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };
  const filterDataInDateRange = (data) => {
    if (startDate === "" && endDate === "") {
      return data;
    } else {
      const newData = data.filter(
        (item) =>
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") >=
            moment(startDate, "YYYY/MM/DD").format("YYYY/MM/DD") &&
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") <=
            moment(endDate, "YYYY/MM/DD").format("YYYY/MM/DD")
      );
      return newData;
    }
  };
  const handleSearch = (data, searchValue, completeData) => {
    if (searchValue === "") {
      return data;
    } else {
      return completeData.filter((el) => {
        for (let key in el) {
          if (
            typeof el[key] === "string" &&
            el[key].toLowerCase().includes(searchValue.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }
  };
  const filteredData = handleSearch(
    ordersData,
    searchValue,
    orderdDataFormated
  );
  const allFilter = (data) => {
    const newData = filterDataInDateRange(data);
    // setOrderdDataFiltert(newData);
    if (newData) {
      return newData;
    } else {
      return;
    }
  };

  const sendtoTcs = (e, item) => {
    e.preventDefault();
    console.log("item: ", JSON.stringify(item));

    const selectedCity = allCities.find((el) => el.label === item?.city);
    console.log("selectedCity11111111: ", selectedCity);

    const descriptionArr = [];
    let totalQuantity = 0;
    for (let j = 0; j < item?.cart?.length; j++) {
      const product = `${item?.cart[j].productName}-(Qty:${item?.cart[j].quantity})`;
      descriptionArr.push(product);
      totalQuantity += item?.cart[j].quantity;
    }

    if (selectedCity?.tcsvalue !== "") {
      const getProductText = (product) => {
        return `${product.productName} (${product.quantity})`;
      };

      const getProductListText = (products) => {
        return products.map((product) => getProductText(product)).join(", ");
      };
      const details = {
        accesstoken: process.env.REACT_APP_TCS_ACCESS_TOKEN,
        consignmentno: "",
        shipperinfo: {
          tcsaccount: "LGEC6613",
          shippername: "PACKAGES CONVERTORS LTD",
          address1:
            "Embrace Distribution Office - KJR Distribution House 35 F, Peco Phaatak, Kacha Jail road, Lahore",
          address2: "",
          address3: "",
          zip: "",
          countrycode: "PK",
          countryname: "Pakistan",
          citycode: "",
          cityname: "Lahore",
          mobile: "03011140458",
        },
        consigneeinfo: {
          consigneecode: "",
          firstname: item?.name,
          middlename: " ",
          lastname: "",
          address1: item?.address,
          address2: "",
          address3: "",
          zip: "",
          countrycode: "PK",
          countryname: "Pakistan",
          citycode: "",
          cityname: selectedCity?.label,
          email: item?.email,
          areacode: "",
          areaname: "",
          blockcode: "",
          blockname: "",
          lat: "",
          lng: "",
          mobile: item?.contact,
        },
        vendorinfo: {
          name: "",
          address1: "",
          address2: "",
          address3: "",
          citycode: "",
          cityname: "",
          mobile: "",
        },
        shipmentinfo: {
          costcentercode: "Embrace01",
          referenceno: item?.orderId,
          contentdesc: getProductListText(item.cart),
          servicecode: "O",
          parametertype: "",
          shipmentdate: "",
          shippingtype: "",
          currency: "PKR",
          codamount: item?.total,
          declaredvalue: null,
          insuredvalue: null,
          transactiontype: "",
          dsflag: "",
          carrierslug: "",
          weightinkg: 0.5,
          pieces: totalQuantity,
          fragile: false,
          remarks: "",

          skus: item?.cart.map((itemww) => ({
            description:
              itemww.description.length >= 3
                ? itemww.description.slice(0, 190)
                : null,
            quantity: parseInt(itemww.pieces),
            weight: 0.5,
            uom: "KG",
            unitprice: itemww.price,
            declaredvalue: null,
            insuredvalue: null,
          })),
        },
      };

      axios
        .post(`${process.env.REACT_APP_TCS_BASE_URL}/booking/create`, details, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TCS_BEARER_TOEN}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("heelo this is the response: ", response);
          const apiresdata = response.data?.consignmentNo;
          console.log("ali ghazaL: ", apiresdata);
          setIsLoadingButton(false);

          OrderServices.statusUpdate(item._id, {
            status: "Dispatched",
          }).then((resss) => {
            OrderServices.updateParcelId(item._id, {
              trackingnumber: apiresdata,
              logisticPatner: "tcs",
            }).then(() => {
              getData();
              notifySuccess(
                "Dispatched Successfully. Awaiting TCS response for order status."
              );
            });
          });
        });
    } else {
      console.log("The City is not exits in tcs_status");
    }
  };

  const sendtoCorier = (e, item) => {
    e.preventDefault();

    setOrderID(item?.orderId);
    setIsLoadingButton(true);
    try {
      let newData = [];
      const cart = item.cart;

      const descriptionArr = [];
      let totalQuantity = 0;
      for (let j = 0; j < cart.length; j++) {
        const product = `${cart[j].productName}-(Qty:${cart[j].quantity})`;
        descriptionArr.push(product);
        totalQuantity += cart[j].quantity;
      }
      //Inticational check

      const data = {
        ORDER_ID: `${item?.orderId}`, //required
        ORDER_TYPE: "COD", //required
        CONSIGNEE_FIRST_NAME: item?.name?.split(" ")[0], //required
        CONSIGNEE_PHONE: item?.contact, //required
        CONSIGNEE_CITY: item?.city, //required
        CONSIGNEE_ADDRESS: item?.address, //required
        PIECES: totalQuantity, //required
        COD: item?.total, //required
        DESCRIPTION: descriptionArr?.join(", "), //required
        WEIGHT: 0.5, //required
        SHIPPER_ADDRESS_ID: `${process.env.REACT_APP_SWIFT_SHIPPER_ID}`, //required
      };
      const emptyFields = [];
      for (const key in data) {
        if (!data[key]) {
          emptyFields.push(key);
        }
      }
      if (emptyFields.length > 0) {
        const notificationMessage = `Empty fields found on orders: ${emptyFields.join(
          ", "
        )}`;
        setIsLoadingButton(false);
        notifyError(notificationMessage);
      } else {
        const newOrder = {
          ORDER_ID: `${item?.orderId}`, //required
          ORDER_TYPE: "COD", //required
          CONSIGNEE_FIRST_NAME: item?.name?.split(" ")[0], //required
          CONSIGNEE_LAST_NAME: item?.name?.split(" ")[1],
          CONSIGNEE_EMAIL: item?.email,
          CONSIGNEE_PHONE: item?.contact, //required
          CONSIGNEE_CITY: item?.city, //required
          CONSIGNEE_ADDRESS: item?.address, //required
          PACKAGING: "Flyer",
          ORIGIN_CITY: "LHE",
          PIECES: totalQuantity, //required
          COD: item?.total, //required
          DESCRIPTION: descriptionArr?.join(", "), //required
          WEIGHT: 0.5, //required
          SHIPPER_ADDRESS_ID: `${process.env.REACT_APP_SWIFT_SHIPPER_ID}`, //required
        };

        newData.push(newOrder);

        axios
          .post(
            `${process.env.REACT_APP_SWIFTAPI_BASE_URL}${process.env.REACT_APP_SWIFT_API_KEY}/api-upload`,
            newData,
            {
              headers: {
                Authorization: `${process.env.REACT_APP_SWIFT_API_AUTH_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((ress) => {
            setIsLoadingButton(false);
            const swiftRes = ress?.data?.data[0];
            OrderServices.statusUpdate(item._id, {
              status: "Dispatched",
            }).then((resss) => {
              OrderServices.updateParcelId(item._id, {
                trackingnumber: swiftRes.parcelId,
                logisticPatner: "swift",
              }).then(() => {
                getData();
                notifySuccess(
                  "Dispatched Successfully. Awaiting Swyft response for order status."
                );
              });
            });
          })
          .catch((err) => {
            setIsLoadingButton(false);
            notifyError(err.response.data.error.message);
          });
      }
    } catch (error) {
      setIsLoadingButton(false);
      notifyError(error);
    }
  };

  const headersCsv = [
    { label: "Order ID", key: "orderId" },
    { label: "Date", key: "date" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "contact" },
    { label: "Product", key: "products" },
    { label: "Order Type", key: "subscriptionType" },
    { label: "Amount", key: "total" },
    { label: "Status", key: "status" },
    { label: "Tcs Status", key: "tcs_status" },
    { label: "Rejection Reason", key: "rejection_reason" },
  ];

  const handleRejectionStatus = (id) => {
    const body = {
      rejection_reason: selectedReason,
    };

    OrderServices.rejectionReason(id, body).then((Res) => {
      document.querySelector("#closepopup").click();
      setSelectedReason("");
      setRejectionId(null);
    });
  };

  const checkTcsStatus = (e, orderid, trackingnumber) => {
    e.preventDefault();
    OrderServices.checkTCSStatus(orderid, trackingnumber)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkAllTcsStatus = (e) => {
    e.preventDefault();
    OrderServices.checkAllTCSStatus()
      .then((res) => {
        notifySuccess(
          "All Statuses updated successfully"
        );
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkSwyftStatus = (e, orderid, trackingnumber) => {
    e.preventDefault();
    OrderServices.checkSwyftStatus(orderid, trackingnumber)
      .then((res) => {
        console.log("bhai bhai bhai: ", res);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log("allFilter(filteredData)", allFilter(filteredData));

  return (
    <>
      <Helmet>
        <title>Orders - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Orders</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Orders</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <div className="float-end">
                    <div className=" mb-3">
                      <RangePicker
                        allowClear="true"
                        onCalendarChange={handleCalendarChange}
                        onChange={onDateClear}
                      />
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => onButtonClick()}
                        className="btn btn-primary btn-sm waves-effect waves-light"
                        style={{ marginRight: "3px" }}
                      >
                        <i
                          className="mdi mdi-magnify"
                          style={{ marginRight: "5px" }}
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  {allOrders === undefined ||
                  allOrders === null ||
                  allOrders?.length === 0 ? (
                    <>
                      <TableLoader />
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="row w-30 mb-3">
                          <div className="col d-none">
                            <select
                              className="form-select form-select-sm"
                              value={actionValue}
                              onChange={(e) => setActionValue(e.target.value)}
                            >
                              <option value="">Bulk Action</option>
                              <option value="dispatch">Dispatch</option>
                              <option value="multidispatch">
                                Merge & Dispatch
                              </option>
                            </select>
                          </div>
                          <div className="col-2 d-none">
                            <button
                              // onClick={(e) => applyBulkAction(e)}
                              type="button"
                              className="btn btn-primary btn-sm waves-effect waves-light"
                            >
                              Apply
                            </button>
                          </div>
                          <div className="col">
                            <CSVLink
                              className="btn btn-primary btn-sm waves-effect waves-light"
                              data={
                                startDate === "" &&
                                endDate === "" &&
                                searchValue === ""
                                  ? orderdDataFormated.map((item) => ({
                                      ...item,
                                      date: moment(item?.createdAt).format(
                                        "DD/MM/YYYY"
                                      ),
                                      // Add other properties you want to include in the CSV
                                    }))
                                  : allFilter(filteredData).map((item) => ({
                                      ...item,
                                      date: moment(item?.createdAt).format(
                                        "DD/MM/YYYY"
                                      ),
                                      // Add other properties you want to include in the CSV
                                    }))
                              }
                              filename={"orders.csv"}
                              headers={headersCsv}
                            >
                              Export Csv
                            </CSVLink>
                            <button
                              onClick={(e) => checkAllTcsStatus(e)}
                              type="button"
                              className="btn btn-primary btn-sm waves-effect waves-light"
                              style={{ marginLeft: "3px" }}
                            >
                              Update all TCS Statuses
                            </button>
                          </div>
                        </div>
                        <div className="row w-30 mb-3 ">
                          <div
                            className="row"
                            style={{ justifyContent: "end" }}
                          >
                            <div
                              className="col-2"
                              style={{
                                justifyContent: "center",
                                alignContent: "center !important",
                              }}
                            >
                              <label
                                style={{
                                  fontWeight: "normal",
                                  whiteSpace: "nowrap",
                                  width: "150px",
                                  alignItems: "center",
                                }}
                              >
                                Search:
                              </label>
                            </div>
                            <div className="col-5">
                              <input
                                type="search"
                                className="form-control form-control-sm"
                                placeholder=""
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {isOrderLoading ? (
                          <TableLoader />
                        ) : (
                          <table className="table table-striped mb-0 torder">
                            <thead>
                              <tr>
                                {/* <th width="1%">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </th> */}
                                <th>#Id</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Products</th>
                                <th>Item Qty</th>
                                <th>Order Type</th>
                                <th>Ammount</th>
                                <th>Internal Status</th>
                                <th>TCS Status</th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            {/*  */}
                            <tbody>
                              <>
                                {allFilter(filteredData)?.map((el, index) => (
                                  <tr key={el?._id}>
                                    {/* <th>
                                      <input
                                        onChange={(e) =>
                                          handelAddSelectOrders(e, el)
                                        }
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`${el?._id}`}
                                      />
                                    </th> */}
                                    <th scope="row">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/product/orders/detail/${el?.orderId}`
                                          )
                                        }
                                      >
                                        {el?.orderId}
                                      </span>
                                    </th>
                                    <td>
                                      <span>
                                        {moment(el?.createdAt).format(
                                          "DD/MM/YY: HH:mm"
                                        )}
                                      </span>
                                    </td>

                                    <td>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() =>
                                          navigate(`/customer/${el?.userId}`)
                                        }
                                      >
                                        {el?.name}
                                      </span>
                                    </td>
                                    <td>
                                      <>
                                        <ul style={{ margin: 0, padding: 0 }}>
                                          {el?.cart.map((item) => (
                                            <li
                                              key={item._id}
                                              style={{
                                                listStyle: "none",
                                                margin: 0,
                                                padding: 0,
                                              }}
                                            >
                                              <span>
                                                {item.productName} - (Qty:{" "}
                                                {item.quantity})
                                              </span>
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    </td>
                                    <td>
                                      {el?.cart.reduce(
                                        (accumulator, currentValue) => {
                                          return (
                                            accumulator + currentValue.quantity
                                          );
                                        },
                                        0
                                      )}
                                    </td>
                                    <td>{el?.subscriptionType}</td>
                                    <td>{commaSperate(el?.total)}</td>
                                    <td>
                                      {isLoading ? (
                                        ""
                                      ) : (
                                        <>
                                          {el?.status === "Dispatched" ? (
                                            <OrderStatusDelivered
                                              status={el?.status}
                                              item={el}
                                              getData={getData}
                                              setRejectionId={setRejectionId}
                                            />
                                          ) : (
                                            <OrderStatus
                                              status={el?.status}
                                              item={el}
                                              getData={getData}
                                              setRejectionId={setRejectionId}
                                            />
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td>{el?.tcs_status}</td>

                                    <td className="d-flex twrap">
                                      {el?.status === "Dispatched" ||
                                      (el?.status === "Delivered" &&
                                        el?.tcs_status) ? (
                                        <a
                                          href={`https://www.tcsexpress.com/track/${el.trackingnumber}`}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="btn btn-primary btn-sm waves-effect waves-light"
                                          style={{ marginRight: 3 }}
                                        >
                                          Track Shipment
                                        </a>
                                      ) : isLoadingButton &&
                                        isOrderID === el?.orderId ? (
                                        <>
                                          <button
                                            onClick={(e) => sendtoTcs(e, el)}
                                            type="button"
                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                            style={{ marginRight: 3 }}
                                          >
                                            <div
                                              className="spinner-border text-white m-1"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading... Send to Courier
                                              </span>
                                            </div>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={(e) => sendtoTcs(e, el)}
                                            type="button"
                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                            style={{ marginRight: 3 }}
                                          >
                                            Send to Courier
                                          </button>
                                        </>
                                      )}
                                      {el.trackingnumber === "" ||
                                      el.trackingnumber === undefined ? (
                                        <>
                                          {" "}
                                          <button
                                            disabled
                                            type="button"
                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                            style={{ marginRight: 3 }}
                                          >
                                            Check Status
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <button
                                            onClick={(e) =>
                                              checkTcsStatus(
                                                e,
                                                el.orderId,
                                                el.trackingnumber
                                              )
                                            }
                                            type="button"
                                            className="btn btn-primary btn-sm waves-effect waves-light"
                                            style={{ marginRight: 3 }}
                                          >
                                            Check Status
                                          </button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            </tbody>
                          </table>
                        )}
                        {/*  */}
                      </div>
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <div className="row w-30 mt-5">
                          <Pagination
                            itemCount={allOrders?.length}
                            pageSize={pageSize}
                            onPageChange={handelPageChange}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        id="oderCanvelModel"
        className="btn btn-success waves-effect waves-light invisble"
        data-bs-toggle="modal"
        data-bs-target=".bs-example-modal-sm"
      >
        Large modal
      </button>
      <div
        className="modal fade bs-example-modal-sm"
        tabIndex={-1}
        aria-labelledby="mySmallModalLabel"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="mySmallModalLabel">
                Select Rejection Reason
              </h5>
              <button
                type="button"
                className="btn-close invisible"
                id="closepopup"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Select
                options={allRejections}
                styles={SelectStyle}
                onChange={(e) => setSelectedReason(e.value)}
              />
              <div className="col-md-12 mt-4">
                <button
                  className="btn btn-primary"
                  // type="submit"
                  onClick={() => handleRejectionStatus(rejectionId)}
                  style={{ width: "100%" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
};

export default Orders;
