import React, { useState, useEffect } from "react";
import ReviewsServices from "../../../services/ReviewsServices";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

const Reviews = () => {
  const navigate = useNavigate();

  const [reviews, setReviews] = useState([]);

  const getData = () => {
    ReviewsServices.listAllReviews()
      .then((res) => {
        setReviews(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);
  
  const handleChangeStatus = (e, id) => {
    e.preventDefault();
    ReviewsServices.changeReviewStatus(id)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Helmet>
        <title>Products Reviews - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Products Reviews</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Products Reviews
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Comment</th>
                            <th>Stars</th>
                            <th>Customer Id</th>
                            <th>Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {reviews &&
                            reviews.map((el, index) => (
                              <tr key={el?._id}>
                                <td>{el?.productName}</td>
                                <td style={{ width: "50%" }}>{el?.comment}</td>
                                <td>{el?.stars}</td>
                                <td
                                  onClick={() =>
                                    navigate(`/customer/${el?.customerId}`)
                                  }
                                  style={{
                                    textDecoration: "underline",
                                    fontSize: "bold",
                                  }}
                                >
                                  {el?.customerId}
                                </td>
                                <td>
                                  {el?.isApproved ? (
                                    <span
                                      className="btn-rounded waves-effect waves-light btn btn-success"
                                      onClick={(e) =>
                                        handleChangeStatus(e, el._id)
                                      }
                                    >
                                      Approved
                                    </span>
                                  ) : (
                                    <span
                                      className="btn-rounded waves-effect waves-light btn btn-danger"
                                      onClick={(e) =>
                                        handleChangeStatus(e, el._id)
                                      }
                                    >
                                      Rejected
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
