import requests from "./httpService";

const ProductCategoryServices = {
  getAllProductsCategory() {
    return requests.get(`/products/category/all`);
  },
  createProductsCategory(body) {
    return requests.post(`/products/category/add`, body);
  },
  deleteProductsCategory(id) {
    return requests.delete(`/products/category/${id}`);
  },

  updateProductsCategory(id, body) {
    return requests.put(`/products/category/${id}`, body);
  },
};

export default ProductCategoryServices;
