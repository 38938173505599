import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductServices from "../../../services/ProductServices";
import { commaSperate } from "../../../Functions/Helper";

const ProductDetail = () => {
  const [productData, setProductData] = useState(null);

  const params = useParams();
  const id = params?.id;

  const gerDataById = useCallback(() => {
    ProductServices.getProductById(id)
      .then((res) => {
        setProductData(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  useEffect(() => {
    gerDataById();
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Product Detail</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#">Ecommerce</a>
                    </li>
                    <li className="breadcrumb-item active">Product Detail</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body" style={{ height: "75vh" }}>
                  <div className="row">
                    <div className="col-xl-5">
                      <div className="product-detail">
                        <div className="row">
                          <div className="col-12">
                            <div
                              className="tab-content position-relative"
                              id="v-pills-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="product-1"
                                role="tabpanel"
                              >
                                <div className="product-img">
                                  <img
                                    src={
                                      process.env.REACT_APP_API_BASE_IMAGE_URL +
                                      productData?.productImage[0]
                                    }
                                    alt=""
                                    crossOrigin="anonymous"
                                    className="img-fluid mx-auto d-block"
                                    data-zoom={
                                      process.env.REACT_APP_API_BASE_IMAGE_URL +
                                      productData?.productImage[0]
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7">
                      <div className="mt-4">
                        <h5 className="font-size-14 mb-3">
                          Product description:
                        </h5>
                        <div className="product-desc">
                          <ul
                            className="nav nav-tabs nav-tabs-custom"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="desc-tab"
                                data-bs-toggle="tab"
                                href="#desc"
                                role="tab"
                              >
                                Description
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="specifi-tab"
                                data-bs-toggle="tab"
                                href="#specifi"
                                role="tab"
                              >
                                Specifications
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content p-4">
                            <div
                              className="tab-pane fade"
                              id="desc"
                              role="tabpanel"
                            >
                              <div className="row">
                                <div className="col-sm-9 col-md-10">
                                  <div className="text-muted p-2">
                                    {productData?.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade show active"
                              id="specifi"
                              role="tabpanel"
                            >
                              <div className="table-responsive">
                                <table className="table table-nowrap mb-0">
                                  <tbody>
                                    <tr>
                                      <th scope="row" style={{ width: "20%" }}>
                                        Category
                                      </th>
                                      <td>{productData?.productCategory}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">SKU</th>
                                      <td>{productData?.sku}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Status</th>
                                      <td>{productData?.status}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Price</th>
                                      <td>
                                        {commaSperate(productData?.price)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Sale Price</th>
                                      <td>
                                        {commaSperate(productData?.salePrice)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Pieces</th>
                                      <td>{productData?.pieces}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Quantity</th>
                                      <td>
                                        {productData?.quantity
                                          ? productData?.quantity
                                          : "0"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end row */}
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* container-fluid */}
      </div>
    </div>
  );
};

export default ProductDetail;
