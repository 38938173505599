import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import OrderServices from "../../../services/OrderServices";
import ProductServices from "../../../services/ProductServices";

const ProductsOrder = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [cart, setCart] = useState([]);

  const cartItems = cart?.slice();
  const getData = useCallback(() => {
    ProductServices.getAllProducts()
      .then((res) => {
        setAllProducts(res);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const addToCart2 = (product) => {
    let alreadyExists = false;
    cartItems.forEach((x) => {
      if (x._id === product._id) {
        alreadyExists = true;
        x.count++;
      }
    });
    if (!alreadyExists) {
      cartItems.push({ ...product, count: 1 });
    }
    secureLocalStorage.setItem("cartItems", JSON.stringify(cartItems));
    setCart(cartItems);
  };

  const subTotal = cartItems.reduce((a, c) => a + c.salePrice * c.count, 0);

  const placeOrder = (e) => {
    e.preventDefault();
    const body = {
      userId: "10001",
      produsts: "userId",
      phone: "req.body.numdasdasber",
      postalcode: 2222,
      cart: cart,
      name: "M.Zanaen Ullah",
      address: "Street#3",
      city: "Lahore",
      contact: "03204405836",
      country: "Pakistan1111",
      zipCode: "540000",
      subTotal: subTotal,
      shippingCost: 0,
      discount: 0,
      total: subTotal,
      shippingOption: 0,
      paymentMethod: 1,
      cardInfo: 1,
      subscriptionType: "Monthly",
      nextOrderDate: moment(),
      // status: true,
    };
    OrderServices.submitOrder(body)
      .then((res) => console.log(res))
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <Helmet>
        <title>Products - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Products</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Products</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="">
                            Cart length = {cartItems?.length}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <div className="form-inline float-md-end">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm waves-effect waves-light"
                              // data-bs-toggle="modal"
                              // data-bs-target="#myModal"
                              onClick={(e) => placeOrder(e)}
                            >
                              Place Order
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {(allProducts && allProducts)?.map((el) => (
                          <div className="col-xl-2 col-sm-4" key={el._id}>
                            <div className="product-box">
                              <div className="product-img pt-4 px-4">
                                <div className="product-ribbon badge bg-warning">
                                  Trending
                                </div>
                                <div className="product-wishlist">
                                  <Link to="#">
                                    <i className="mdi mdi-heart-outline" />
                                  </Link>
                                </div>
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASE_IMAGE_URL +
                                    el.productImage
                                  }
                                  onError={(e) =>
                                    (e.target.src =
                                      "/assets/images/noimage.jpg")
                                  }
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                  crossOrigin="anonymous"
                                />
                              </div>
                              <div className="text-center product-content p-4">
                                <h5 className="mb-1">
                                  <Link to="#" className="text-dark">
                                    {el.productName}
                                  </Link>
                                </h5>
                                <p className="text-muted font-size-13">
                                  {el.productCategory}
                                </p>
                                <h5 className="mt-3 mb-0">
                                  <span className="text-muted me-2">
                                    <del>${el.price}</del>
                                  </span>
                                  ${el.salePrice}
                                </h5>
                                <ul className="list-inline mb-0 text-muted product-color">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm waves-effect waves-light"
                                    onClick={() => addToCart2(el)}
                                  >
                                    Add To Cart
                                  </button>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        id="myModal"
                        className="modal fade"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="myModalLabel">
                                Modal Heading
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-2 col-form-label"
                                >
                                  Text
                                </label>
                                <div className="col-md-10">
                                  <input
                                    className="form-control"
                                    type="text"
                                    defaultValue="Artisanal kale"
                                    id="example-text-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-light waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default connect((state) => ({ products: state.allProducts }), {
//   addToCart,
// })(ProductsOrder);

export default ProductsOrder;
