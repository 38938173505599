import requests from "./httpService";

const SymptomCategoryServices = {
  createCategory(body) {
    return requests.post(`/symptoms/category/create`, body);
  },
  getAllCategory() {
    return requests.get(`/symptoms/category/all`);
  },
  getCategoryById(id) {
    return requests.get(`/symptoms/category/${id}`);
  },
  updateCategory(id, body) {
    return requests.put(`/symptoms/category/${id}`, body);
  },
  deleteCategory(id) {
    return requests.delete(`/symptoms/category/${id}`);
  },
};

export default SymptomCategoryServices;
