import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReportsServices from "../../services/ReportsServices";
import csvDownload from "json-to-csv-export";

const PromoCodeReport = () => {
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    ReportsServices.PromoCodeReport()
      .then((res) => {
        console.log(res);
        setData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const dataToConvert = {
    data: data,
    filename: "Promo Code Report",
    delimiter: ",",
    headers: [
      "Code",
      "Customer Name",
      "Order Date",
      "Contact #",
      "Products",
      "Discount Ammount",
      "Total Ammount",
    ],
  };

  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="float-end pb-2">
                        <Link
                          onClick={() => csvDownload(dataToConvert)}
                          to="#"
                          className="btn btn-primary btn-sm waves-effect waves-light"
                        >
                          Export csv
                        </Link>
                      </div>
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Customer Name</th>
                            <th>Order Date</th>
                            <th>Contact #</th>
                            <th>Products</th>
                            <th>Discount Ammount</th>
                            <th>Total Ammount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data &&
                            data?.map((el, index) => (
                              <tr key={index}>
                                <td>{el?.code}</td>
                                <td>{el?.customerName}</td>
                                <td>{el?.orderDate}</td>
                                <td>{el?.contact}</td>
                                <td>
                                  {el?.products.map((product) => product)}
                                </td>
                                <td>{el?.discountAmmount}</td>
                                <td>{el?.totalAmmount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCodeReport;
