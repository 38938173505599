import requests from "./httpService";

const SubscriptionServices = {
  GetAllSubscriptions() {
    return requests.get("/subscription/all");
  },
  GetSubscriptionOfUser(customerId) {
    return requests.get(`/subscription/user/${customerId}`);
  },
};

export default SubscriptionServices;
