import requests from "./httpService";

const DashboardServices = {
  getTotalData(body) {
    return requests.get(`/dashboard/totaldata`, body);
  },
  getAddressById(id) {
    console.log(id);
    return requests.get(`/dashboard/customer/${id}`);
  },
};

export default DashboardServices;
