import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import RejectionService from "../../../services/RejectionService";
import TableLoader from "../../../Reuseable/TableLoader";

const RejectReason = () => {
  const [reason, setReason] = useState("");

  const [allReason, setAllReason] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const getData = useCallback(() => {
    RejectionService.getRejection()
      .then((res) => {
        setAllReason(res);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const postData = (e) => {
    e.preventDefault();
    RejectionService.createRejection({ label: reason, value: reason }).then(
      (res) => {
        setReason("");
        getData();
      }
    );
  };

  const editReason = (e, id) => {
    e.preventDefault();
    const body = {
      label: reason,
      value: reason,
    };
    RejectionService.updateRejection(id, body).then((res) => {
      getData();
    });
  };

  const deleteReason = (e, id) => {
    e.preventDefault();
    RejectionService.deleteReasons(id).then((res) => {
      getData();
    });
  };

  const handelEditClick = (e, item) => {
    setIsEditing(true);
    setEditId(item?._id);
    setReason(item?.label);
  };
  return (
    <>
      <Helmet>
        <title>Rejection Reason - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">All Rejections</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">All Rejections</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={
                        isEditing === true
                          ? (e) => editReason(e, editId)
                          : (e) => postData(e)
                      }
                    >
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Reason
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="card">
                  <div className="card-body">
                    {allReason === undefined || allReason === null ? (
                      <>
                        <TableLoader />
                      </>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>

                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {allReason &&
                              allReason?.map((el, index) => (
                                <tr key={el?._id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{el?.label}</td>

                                  <td>
                                    <i
                                      className="mdi mdi-trash-can-outline iconsize"
                                      onClick={(e) => deleteReason(e, el._id)}
                                    />
                                    <i
                                      className="mdi mdi-pencil-box-outline iconsize"
                                      onClick={(e) => handelEditClick(e, el)}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectReason;
