import requests from "./httpService";

const PromotionSetvices = {
  getAllPromotions() {
    return requests.get(`/promotion/all`);
  },
  getActivePromotions() {
    return requests.get(`/promotion/active`);
  },
  createPromotions(body) {
    return requests.post(`/promotion/add`, body);
  },
  deletePromotions(id) {
    return requests.delete(`/promotion/delete/${id}`);
  },
};

export default PromotionSetvices;
