import requests from "./httpService";

const CustomerServices = {
  getAllCustomers(body) {
    return requests.get(`/customers`, body);
  },
  getCustomerByOrders(body) {
    return requests.get(`/customers/all`, body);
  },
  getCustomerById(id) {
    return requests.get(`/customers/${id}`);
  },
  deleteCustomer(id) {
    return requests.delete(`/customers/${id}`);
  },
};

export default CustomerServices;
