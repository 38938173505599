import requests from "./httpService";

const AnaylaticsServices = {
  getAllDauData(startDateClick, endDateClick) {
    return requests.get(
      `/anaylatics/getAllDauData?start_date=${startDateClick}&end_date=${endDateClick}`
    );
  },
};

export default AnaylaticsServices;
