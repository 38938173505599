import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ reportData }) => {
  const data = {
    series: [reportData[0]],
    options: {
      colors: ["#9a8bbb", "#9a8bbb", "#9a8bbb"],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reportData[1]?.month,
      },
      yaxis: {
        title: {
          text: "Orders",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return  val ;
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default BarChart;
