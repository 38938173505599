import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionServices from "../../../services/SubcriptionService";
import TableLoader from "../../../Reuseable/TableLoader";

const Subscriptions = () => {
  const [allSubscription, setAllSSubscription] = useState([]);
  const navigate = useNavigate();

  const getData = useCallback(() => {
    SubscriptionServices.GetAllSubscriptions()
      .then((res) => {
        setAllSSubscription(res);
      })
      .catch((error) => console.warn(error.message));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Subscription - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Subscriptions</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Subscriptions</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="card">
                <div className="card-body">
                  {allSubscription?.length === 0 ||
                  allSubscription?.length === null ? (
                    <TableLoader />
                  ) : (
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Subscribed On</th>
                          <th>Next Order On</th>
                          <th>Plan</th>
                          <th>Products</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {/*  */}
                      <tbody>
                        <>
                          {allSubscription &&
                            allSubscription?.map((el) => (
                              <tr key={el?._id}>
                                <th scope="row">
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      navigate(`/customer/${el?.userId}`)
                                    }
                                  >
                                    {el?.CustomersData?.full_name}
                                  </span>
                                </th>
                                <td>
                                  {moment(el?.createdAt).format("DD/MM/YY")}
                                </td>
                                <td>

                                
                                  {moment(
                                    el?.nextOrderDate,
                                    "DD-MM-YYYY"
                                  ).format("DD/MM/YY")}
                                </td>
                                <td>{el?.subscriptionType}</td>
                                <td>
                                  <ul style={{ margin: 0, padding: 0 }}>
                                    {el?.products?.productsData?.productsin?.map(
                                      (item, index) => (
                                        <li
                                          key={++index}
                                          style={{
                                            listStyle: "none",
                                            margin: 0,
                                            padding: 0,
                                          }}
                                        >
                                          <span>
                                            {item.productName}- (Qty:{" "}
                                            {item.count})
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </td>
                                <td>{el?.status ? "Active" : "Deactive"}</td>
                              </tr>
                            ))}
                        </>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
