import requests from "./httpService";

const UploadServices = {
  UploadSingle(body) {
    return requests.post(`/upload`, body);
  },
  UploadMultiple(body) {
    return requests.post(`/upload/multiple`, body);
  },
};

export default UploadServices;
