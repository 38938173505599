import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import EmailServices from "../../../services/EmailServices";
import OrderServices from "../../../services/OrderServices";
import { commaSperate } from "../../../Functions/Helper";
import { notifySuccess } from "../../../utils/toast";

const OrderDetail = () => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const params = useParams();
  const id = params.id;

  const getData = useCallback(() => {
    OrderServices.getOrderById(id)
      .then((res) => {
        setOrderDetail(res);
        setTrackingNumber(res.trackingnumber);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const onSendPress = (e, item) => {
    e.preventDefault();
    console.log("item", item);
    const data = {
      email: item.email,
      name: item.name,
      cart: item.cart,
      total: item.total,
      paymentMethod: item.paymentMethod,
      address: item.address,
      city: item.city,
      country: item.country,
      zipCode: item.zipCode,
    };
    EmailServices.orderProcedeMail(data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const updateTrackingId = (e) => {
    e.preventDefault();
    OrderServices.updateOrderTrackingId(id,{ tracking: trackingNumber }).then((res)=>{
      console.log("res: ", res);
      getData();
      notifySuccess("Tracking ID Updated Successfully!");
    }).catch((err)=>{
      console.log(err)
    })
  };

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Order Detail</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Orders</a>
                      </li>
                      <li className="breadcrumb-item active">Order Detail</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    {/* <div className="Order-title">
                      <h4 className="float-end font-size-16">
                        Order #{orderDetail?.orderId}
                      </h4>
                      <div className="mb-4">
                        <img
                          src="/assets/images/logo-dark.png"
                          alt="logo"
                          height={20}
                        />
                      </div>
                      <div className="text-muted">
                        <p className="mb-1">
                          641 Walton Road, Lahore
                        </p>
                        <p className="mb-1">
                          <i className="uil uil-envelope-alt me-1" />
                          contact@embracecomfort.com
                        </p>
                        <p>
                          <i className="uil uil-phone me-1" /> 012-345-6789
                        </p>
                      </div>
                    </div> 
                    <hr className="my-4" /> */}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="text-muted">
                          <h5 className="font-size-16 mb-3">Billed To:</h5>
                          <h5 className="font-size-15 mb-2">
                            {orderDetail?.name}
                          </h5>
                          <p className="mb-1">
                            {orderDetail?.address}, {orderDetail?.city},{" "}
                            {orderDetail?.country}
                          </p>
                          <p>{orderDetail?.contact}</p>
                          <h5 className="font-size-16 mb-3">
                            Update Tracking Id:
                          </h5>
                          <div className="d-flex mb-3">
                            <input
                              type="text"
                              value={trackingNumber}
                              onChange={(e) =>
                                setTrackingNumber(e.target.value)
                              }
                              className="form-control"
                              style={{ width: "25%" }}
                            />
                            <button onClick={(e)=>updateTrackingId(e)} className="btn btn-primary btn-sm waves-effect waves-light mx-3">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5 className="font-size-16 mb-1">Order No:</h5>
                            <p>#{orderDetail?.orderId}</p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">Order Date:</h5>
                            <p>
                              {moment(orderDetail?.createdAt).format(
                                "MMM DD, YYYY"
                              )}
                            </p>
                          </div>
                          {orderDetail?.rejection_reason && (
                            <div className="mt-4">
                              <h5 className="font-size-16 mb-1">
                                Rejection Reason:
                              </h5>
                              <p>{orderDetail?.rejection_reason}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="py-2">
                      <h5 className="font-size-15">Order summary</h5>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: 70 }}>No.</th>
                              <th>Item</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th className="text-end" style={{ width: 120 }}>
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetail &&
                              orderDetail?.cart?.map((el, index) => (
                                <tr key={el._id}>
                                  <th scope="row">{++index}</th>
                                  <td>
                                    <h5 className="font-size-15 mb-1">
                                      {el?.productName}
                                    </h5>
                                  </td>
                                  <td>Rs{commaSperate(el?.salePrice)}</td>
                                  <td>{el?.quantity}</td>
                                  <td className="text-end">
                                    Rs
                                    {commaSperate(el?.salePrice * el?.quantity)}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <th scope="row" colSpan={4} className="text-end">
                                Sub Total
                              </th>
                              <td className="text-end">
                                Rs{commaSperate(orderDetail?.subTotal)}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colSpan={4}
                                className="border-0 text-end"
                              >
                                Discount :
                              </th>
                              <td className="border-0 text-end">
                                Rs{commaSperate(orderDetail?.discount)}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colSpan={4}
                                className="border-0 text-end"
                              >
                                Shipping Charge :
                              </th>
                              <td className="border-0 text-end">Rs0</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                colSpan={4}
                                className="border-0 text-end"
                              >
                                Total
                              </th>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  Rs{commaSperate(orderDetail?.total)}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="d-print-none mt-4">
                        <div className="float-end">
                          <a
                            href="javascript:window.print()"
                            className="btn btn-success waves-effect waves-light me-1"
                          >
                            <i className="fa fa-print" />
                          </a>
                          {/* <Link
                            to="#"
                            onClick={(e) => onSendPress(e, orderDetail)}
                            className="btn btn-primary w-md waves-effect waves-light"
                          >
                            Send
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end row */}
          </div>
          {/* container-fluid */}
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
