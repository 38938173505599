import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { DatePicker } from "antd";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../Reuseable/Pagination";
import { paginate } from "../../../utils/Paginate";
import ProductServices from "../../../services/ProductServices";
import PostCategoryServices from "../../../services/PostCategoryServices";
import DataFunction from "../../../Functions/AllFunctions";
import { commaSperate } from "../../../Functions/Helper";
import TableLoader from "../../../Reuseable/TableLoader";

const Products = () => {
  const [allProducts, setAllProducts] = useState([]);

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { RangePicker } = DatePicker;

  const [startDateClick, setStartDateClick] = useState("");
  const [endDateClick, setEndDateClick] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const productData = paginate(allProducts, currentPage, pageSize);

  const getData = useCallback(() => {
    ProductServices.getAllProducts()
      .then((res) => {
        setAllProducts(res);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handelPageChange = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const onButtonClick = () => {
    setStartDate(startDateClick);
    setEndDate(endDateClick);
  };

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  const filterDataInDateRange = (data) => {
    if (startDate === "" && endDate === "") {
      return data;
    } else {
      const newData = data.filter(
        (item) =>
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") >=
            moment(startDate, "YYYY/MM/DD").format("YYYY/MM/DD") &&
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") <=
            moment(endDate, "YYYY/MM/DD").format("YYYY/MM/DD")
      );
      return newData;
    }
  };
  const handelSearch = (data) => {
    if (searchValue === "") {
      return data;
    } else if (searchValue !== "") {
      if (searchBy === "productName") {
        return data.filter((el) =>
          el?.productName?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "sku") {
        return data.filter((el) =>
          el?.sku?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "pieces") {
        return data.filter((el) =>
          el?.pieces?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    } else if (searchValue !== "" && searchBy === "") {
      return data;
    }
  };

  const allFilter = (data) => {
    const newData = handelSearch(filterDataInDateRange(data));
    return newData;
  };

  const handeDelete = (e, id) => {
    e.preventDefault();
    ProductServices.deleteProduct(id)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <Helmet>
        <title>Products - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Products</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Products</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <div className="float-end">
                    <div className=" mb-3">
                      <RangePicker
                        allowClear="true"
                        onCalendarChange={handleCalendarChange}
                      />
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => onButtonClick()}
                        className="btn btn-primary btn-sm waves-effect waves-light"
                        style={{ marginRight: "3px" }}
                      >
                        <i
                          className="mdi mdi-magnify"
                          style={{ marginRight: "5px" }}
                        />
                        Search
                      </button>
                      <Link
                        to="/product/add"
                        className="btn btn-primary btn-sm waves-effect waves-light"
                      >
                        <i
                          className="mdi mdi-plus"
                          style={{ marginRight: "5px" }}
                        />
                        Add Products
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  {allProducts === undefined ||
                  allProducts === null ||
                  allProducts?.length === 0 ? (
                    <>
                      <TableLoader />
                    </>
                  ) : (
                    <>
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <div className="row w-30 mb-3">
                          <div
                            className="col-2"
                            style={{
                              justifyContent: "center",
                              alignContent: "center !important",
                            }}
                          >
                            <label
                              style={{
                                fontWeight: "normal",
                                whiteSpace: "nowrap",
                                width: "150px",
                                alignItems: "center",
                              }}
                            >
                              Search:
                            </label>
                          </div>

                          <div className="col-5">
                            <select
                              className="form-select form-select-sm"
                              value={searchBy}
                              onChange={(e) => setSearchBy(e.target.value)}
                            >
                              <option value="">Search By</option>
                              <option value="productName">Name</option>
                              <option value="sku">SKU</option>
                              <option value="pieces">Pieces</option>
                            </select>
                          </div>
                          <div className="col-5">
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {(allProducts && allProducts)?.length === 0 ? (
                          <TableLoader />
                        ) : (
                          <table className="table table-striped mb-0 table-responsive">
                            <thead>
                              <tr>
                                <th>SKU</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Sale Price</th>
                                <th>Pieces</th>
                                <th>Status</th>
                                <th>Created At</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {/*  */}
                            <tbody>
                              <>
                                {allFilter(productData && productData)?.map(
                                  (el, index) => (
                                    <tr key={el?._id}>
                                      <th scope="row">{el?.sku}</th>
                                      <td>
                                        {DataFunction.imageCheck(
                                          el?.productImage
                                        )}
                                      </td>
                                      <td>{el?.productName}</td>
                                      <td>{commaSperate(el?.price)}</td>
                                      <td>{commaSperate(el?.salePrice)}</td>
                                      <td>{el?.pieces}</td>
                                      <td>{el?.status}</td>
                                      <td>
                                        {moment(el?.createdAt).format(
                                          "MMM DD,YYYY"
                                        )}
                                      </td>
                                      <td className="icondiv">
                                        <i
                                          className="mdi mdi-trash-can-outline iconsize"
                                          onClick={(e) =>
                                            handeDelete(e, el._id)
                                          }
                                        />
                                        <i
                                          className="mdi mdi-pencil-box-outline iconsize"
                                          onClick={() =>
                                            navigate(`/product/edit/${el._id}`)
                                          }
                                        />
                                        <i
                                          className="mdi mdi-eye iconsize"
                                          onClick={() =>
                                            navigate(
                                              `/product/detail/${el._id}`
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </>
                            </tbody>
                          </table>
                        )}
                        {/*  */}
                      </div>
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <div className="row w-30 mt-5">
                          <Pagination
                            itemCount={allProducts?.length}
                            pageSize={pageSize}
                            onPageChange={handelPageChange}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
