import { DatePicker } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AnaylaticsServices from "../../services/AnaylaticsServices";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const DailyActiveUsers = () => {
  const { RangePicker } = DatePicker;
  const currentDate = new Date();

  const [data, setData] = useState([]);

  const [startDateClick, setStartDateClick] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDateClick, setEndDateClick] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  const getData = (startDateClick, endDateClick) => {
    AnaylaticsServices.getAllDauData(startDateClick, endDateClick)
      .then((res) => {
        setData(res);
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getData(startDateClick, endDateClick);
  }, [endDateClick]);

  const Chartdata = {
    series: [
      {
        name: "Active Users",
        data: data.map((el) => el.count),
      },
    ],
    options: {
      colors: ["#9a8bbb"],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: data.map((el) => el.Date),
      },
      yaxis: {
        title: {
          text: "Active Users",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Daily Unique Users - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                 <div>
                 <h4 className="mb-0">Daily Unique Users</h4>
                  <p>(Total number of users that engage with the app in some way.)</p>
                 </div>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div className="float-end">
                  <div className=" mb-3">
                    <RangePicker
                      allowClear="true"
                      onCalendarChange={handleCalendarChange}
                    />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <div id="chart">
                      <ReactApexChart
                        options={Chartdata.options}
                        series={Chartdata.series}
                        type="bar"
                        height={400}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Active Users</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data?.map((el, index) => (
                              <tr key={index}>
                                <td>{el.Date}</td>
                                <td>{el.count}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyActiveUsers;
