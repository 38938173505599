import { DatePicker } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../Reuseable/Pagination";
import CustomerServices from "../../../services/CustomerServices";
import { paginate } from "../../../utils/Paginate";
import TableLoader from "../../../Reuseable/TableLoader";

const CustomersWithOrder = () => {
  const [getAllCustomers, setGetAllCustomers] = useState([]);

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { RangePicker } = DatePicker;

  const [startDateClick, setStartDateClick] = useState("");
  const [endDateClick, setEndDateClick] = useState("");

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const customerData = paginate(getAllCustomers, currentPage, pageSize);

  const headersCsv = [
    { label: "customer ID", key: "customerId" },
    { label: "Name", key: "full_name" },
    { label: "Phone", key: "phone" },
    { label: "Date of birth", key: "dob" },
    { label: "Date", key: "date" },
  ];

  const flattenedData = getAllCustomers?.map((item) => ({
    customerId: item.customerId,
    full_name: item.full_name,
    phone: item.phone,
    dob: item.dob,
    date: moment(item.createdAt).format("DD/MM/YY"),
  }));

  const getData = () => {
    CustomerServices.getCustomerByOrders()
      .then((res) => {
        setGetAllCustomers(res.customersWithOrders);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    getData();
  }, []);

  const handelPageChange = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const onButtonClick = () => {
    setStartDate(startDateClick);
    setEndDate(endDateClick);
  };

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  const filterDataInDateRange = (data) => {
    if (startDate === "" && endDate === "") {
      return data;
    } else {
      const newData = data.filter(
        (item) =>
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") >=
            moment(startDate, "YYYY/MM/DD").format("YYYY/MM/DD") &&
          moment(item.createdAt, "YYYY/MM/DD").format("YYYY/MM/DD") <=
            moment(endDate, "YYYY/MM/DD").format("YYYY/MM/DD")
      );
      return newData;
    }
  };
  const handelSearch = (data) => {
    if (searchValue === "") {
      return data;
    } else if (searchValue !== "") {
      if (searchBy === "full_name") {
        return data.filter((el) =>
          el?.full_name?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "customerId") {
        return data.filter((el) =>
          el?.customerId?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
      if (searchBy === "phone") {
        return data.filter((el) =>
          el?.phone?.toLowerCase().includes(searchValue?.toLowerCase())
        );
      }
    } else if (searchValue !== "" && searchBy === "") {
      return data;
    }
  };

  const allFilter = (data) => {
    const newData = handelSearch(filterDataInDateRange(data));
    return newData;
  };

  return (
    <>
      <Helmet>
        <title>Customers - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Customers</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Customers</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-8">
                  <div className="float-end">
                    <div className=" mb-3">
                      <RangePicker
                        allowClear="true"
                        onCalendarChange={handleCalendarChange}
                      />
                      &nbsp;
                      <button
                        type="button"
                        onClick={() => onButtonClick()}
                        className="btn btn-primary btn-sm waves-effect waves-light"
                      >
                        <i
                          className="mdi mdi-magnify"
                          style={{ marginRight: "5px" }}
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  {customerData === undefined ||
                  customerData === null ||
                  customerData?.length === 0 ? (
                    <>
                      <TableLoader />
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="row w-30 mb-3">
                          <div className="col">
                            <CSVLink
                              className="btn btn-primary btn-sm waves-effect waves-light"
                              data={flattenedData}
                              filename={"customers with orders.csv"}
                              headers={headersCsv}
                            >
                              <i className="uil-cloud-download"></i> Export Csv
                            </CSVLink>
                          </div>
                        </div>
                        <div className="row w-30 mb-3">
                          <div
                            className="col-2"
                            style={{
                              justifyContent: "center",
                              alignContent: "center !important",
                            }}
                          >
                            <label
                              style={{
                                fontWeight: "normal",
                                whiteSpace: "nowrap",
                                width: "150px",
                                alignItems: "center",
                              }}
                            >
                              Search:
                            </label>
                          </div>

                          <div className="col-5">
                            <select
                              className="form-select form-select-sm"
                              value={searchBy}
                              onChange={(e) => setSearchBy(e.target.value)}
                            >
                              <option value="">Search By</option>
                              <option value="full_name">Name</option>
                              <option value="customerId">Customer Id</option>
                              <option value="phone">Phone</option>
                            </select>
                          </div>
                          <div className="col-5">
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder=""
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {allFilter(customerData && customerData)?.length ===
                        0 ? (
                          <TableLoader />
                        ) : (
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Customer #</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Created At</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            {/*  */}
                            <tbody>
                              <>
                                {allFilter(customerData && customerData)?.map(
                                  (el, index) => (
                                    <tr key={el?._id}>
                                      <th scope="row">{el?.customerId}</th>
                                      <td>{el?.full_name}</td>
                                      <td>{el?.phone}</td>
                                      <td>
                                        {moment(el?.createdAt).format(
                                          "MMM DD,YYYY"
                                        )}
                                      </td>
                                      <td className="icondiv">
                                        {/* <i className="mdi mdi-trash-can-outline iconsize" />
                                        <i className="mdi mdi-pencil-box-outline iconsize" /> */}
                                        <i
                                          className="mdi mdi-eye iconsize"
                                          onClick={() =>
                                            navigate(
                                              `/customer/${el?.customerId}`
                                            )
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </>
                            </tbody>
                          </table>
                        )}
                        {/*  */}
                      </div>
                      <div className="d-flex" style={{ justifyContent: "end" }}>
                        <div className="row w-30 mt-5">
                          <Pagination
                            itemCount={getAllCustomers?.length}
                            pageSize={pageSize}
                            onPageChange={handelPageChange}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersWithOrder;
