import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReportsServices from "../../services/ReportsServices";
import TableLoader from "../../Reuseable/TableLoader";
import ReactApexChart from "react-apexcharts";

const CustomerRetentionAndAcquisition = () => {
  const [reportData, setReportData] = useState([]);

  const getData = useCallback(() => {
    ReportsServices.CustomerRetentionAndAcquisition()
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const data = {
    series: [
      {
        name: "Before Current Month",
        data: reportData.map((el) => el.beforeCurrentMOnth),
      },
      {
        name: "Current Month",
        data: reportData.map((el) => el.currentMonth),
      },
    ],
    options: {
      colors: ["#9a8bbb", "#FF0000"],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reportData.map((el) => el.month),
      },
      yaxis: {
        title: {
          text: "Orders",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Customer Retention And Acquisition</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {reportData.length === 0 ? (
              <TableLoader />
            ) : (
              <>
                {" "}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="card-body">
                        {reportData.length === 0 ? null : (
                          <>
                            <div id="chart">
                              <ReactApexChart
                                options={data?.options}
                                series={data?.series}
                                type="bar"
                                height={400}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRetentionAndAcquisition;
