import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomerServices from "../../../services/CustomerServices";
import OrderServices from "../../../services/OrderServices";
import SubscriptionServices from "../../../services/SubcriptionService";
import { commaSperate } from "../../../Functions/Helper";
import DashboardServices from "../../../services/DashboardServices";

const CustomerDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const userId = params?.id;
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerOrder, setCustomerOrder] = useState(null);
  const [subscriptionOrder, setSubscriptionOrder] = useState(null);
  const [address, setAddress] = useState(null);

  const getData = useCallback(() => {
    CustomerServices.getCustomerById(userId).then((res) => {
      setCustomerInfo(res);
    });
    OrderServices.getOrderByUser(userId).then((res) => {
      setCustomerOrder(res);
    });
    SubscriptionServices.GetSubscriptionOfUser(userId).then((res) => {
      setSubscriptionOrder(res);
    });
    DashboardServices.getAddressById(userId).then((res) => {
      console.log("res", res);
      setAddress(res[0]);
    });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  console.log("customerOrder", customerOrder);

  return (
    <>
      <Helmet>
        <title>Customer {userId} - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Customer# {userId}</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Customer# {userId}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="row">
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Customer Info</h4>
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Full Name</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {customerInfo?.full_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Address</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {address?.address}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>State</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {address?.state}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Country</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {address?.country}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>City</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {address?.city}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Email</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {customerInfo?.email}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Postal Code</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {address?.postalcode}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Phone</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {customerInfo?.phone}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              <label>Customer Id</label>
                            </td>
                            <td
                              style={{
                                padding: "0.25rem 0.75rem",
                              }}
                            >
                              {customerInfo?.customerId}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Invoice/Billing</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Products</h4>
                  </div>
                </div>
              </div>
            </div>

            {/* Order List */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Orders List</h4>
                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Order ID</th>
                            <th>Products</th>
                            <th>Date</th>
                            <th>Total</th>
                            <th>Order Status</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerOrder &&
                            customerOrder.map((el) => (
                              <tr key={el?._id}>
                                <td>
                                  <Link to="#" className="text-body fw-bold">
                                    #{el?.orderId}
                                  </Link>
                                </td>
                                <td>
                                  {el &&
                                    el?.cart.map((cart) => (
                                      <>
                                        {cart?.productName} (Qty:{" "}
                                        {cart?.count || cart?.quantity})
                                        <br />
                                      </>
                                    ))}
                                </td>
                                <td>
                                  {moment(el?.createdAt).format("MMM DD,YYYY")}
                                </td>
                                <td>Rs{commaSperate(el?.total)}</td>
                                <td>{el?.status}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                    onClick={() =>
                                      navigate(
                                        `/product/orders/detail/${el?.orderId}`
                                      )
                                    }
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* end table-responsive */}
                  </div>
                </div>
              </div>
            </div>
            {/* Subscription */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Subscription</h4>
                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Subscription Type</th>
                            <th>Products</th>
                            <th>Next Order Date</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscriptionOrder &&
                            subscriptionOrder.map((el) => (
                              <tr key={el?._id}>
                                <td>
                                  <Link to="#" className="text-body fw-bold">
                                    {el?.subscriptionType}
                                  </Link>
                                </td>
                                <td>
                                  {el &&
                                    el?.products.map((cart) => (
                                      <>
                                        {cart?.productName} (Qty:{" "}
                                        {cart?.quantity})
                                        <br />
                                      </>
                                    ))}
                                </td>
                                <td>
                                  {" "}
                                  {moment(el?.nextOrderDate).format(
                                    "MMM DD,YYYY"
                                  )}
                                </td>
                                <td>
                                  Rs
                                  {commaSperate(
                                    el?.products?.reduce(
                                      (a, c) => a + c.price * c.quantity,
                                      0
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* end table-responsive */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetail;
