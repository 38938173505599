import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReportsServices from "../../services/ReportsServices";
import moment from "moment";
import BarChart from "../Home/BarChart";
import csvDownload from "json-to-csv-export";

const UserSymtoms = () => {
  const [reportData, setReportData] = useState([]);

  const getReportData = () => {
    ReportsServices.TopUsedSymtoms().then((res) => {
      setReportData(res);
    });
  };

  useEffect(() => {
    getReportData();
  }, []);

  const dataToConvert = {
    data: reportData[3]?.csv,
    filename: "Used_Symtoms",
    delimiter: ",",
    headers: ["Sympton Name", "Count"],
  };

  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Symptoms Reports</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    {reportData.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <BarChart reportData={reportData} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="float-end pb-2">
                        <Link
                          onClick={() => csvDownload(dataToConvert)}
                          to="#"
                          className=""
                        >
                          <p style={{ textDecorationLine: "underline" }}>
                            Export csv
                          </p>
                        </Link>
                      </div>
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Sympton Name</th>
                            <th>No. of time used</th>
                          </tr>
                        </thead>

                        <tbody>
                          {reportData[3]?.csv?.map((item) => (
                            <>
                              <tr>
                                <td>{item.symptom}</td>
                                <td>{item.count}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSymtoms;
