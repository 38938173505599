import requests from "./httpService";

const ReportsServices = {
  currentMonthOrder(sdate, edate, body) {
    return requests.post(
      `/reports/monthlyOrdersStats?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  MonthlySubscriptons(sdate, edate, body) {
    return requests.post(
      `/reports/monthlySubscriptionStats?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  currentMonthDailyOrder(sdate, edate, body) {
    return requests.post(
      `/reports/dailyOrdersStats?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  SkuTrends(sdate, edate, body) {
    return requests.post(
      `/reports/skuTrends?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  RecurringOrders(sdate, edate, body) {
    return requests.post(
      `/reports/monthlyrecurringOrders?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  OrderStatuses(sdate, edate, body) {
    return requests.post(
      `/reports/OrdersStatusesStats?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  TopTenOrderedCities(sdate, edate, body) {
    return requests.post(
      `/reports/topTenCities?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  TopUsedSymtoms(body) {
    return requests.get(`/reports/topSumptoms`, body);
  },
  PromoCodeReport() {
    return requests.get(`/reports/promoCodeReport`);
  },
  CustomerRetentionAndAcquisition() {
    return requests.get(`/reports/customer-retention-and-acquisition-report`);
  },

  symtponReportDash(sdate, edate, body) {
    return requests.get(
      `/customers/graphpercentagesymptons?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },

  cycleReportDash(sdate, edate, body) {
    return requests.get(
      `/customers/graphpercentagecycle?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
  percentOrderReportDash(sdate, edate, body) {
    return requests.get(
      `/customers/graphpercentageorders?start_date=${sdate}&end_date=${edate}`,
      body
    );
  },
};

export default ReportsServices;
