import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import PostCategoryServices from "../../../services/PostCategoryServices";
import ProductServices from "../../../services/ProductServices";
import ImageUpload from "./ImageUpload";
import ProductCategoryServices from "../../../services/ProductCategoryServices";

const EditProducts = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [sku, setSKU] = useState("");
  const [piece, setPiece] = useState("");
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [SalePrice, setSalePrice] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState([]);
  const [imagesName, setImagesName] = useState([]);
  const [Uploading, setUploading] = useState(false);

  const [allCategories, setAllCategories] = useState([]);

  const params = useParams();
  const id = params?.id;

  const gerDataById = useCallback(() => {
    ProductServices.getProductById(id)
      .then((res) => {
        const imagesData = [];

        for (let i = 0; i < res?.productImage?.length; i++) {
          const element = res?.productImage[i];
          const obj = {
            uid: ++i,
            name: element,
            status: "done",
            url: process.env.REACT_APP_API_BASE_IMAGE_URL + element,
          };
          imagesData.push(obj);
        }

        setImages(imagesData);

        const data = res;
        console.log(data);
        setTitle(data.productName);
        setSKU(data.sku);
        setPiece(data.pieces);
        setQuantity(data.quantity);
        setDescription(data.description);
        setPrice(data.price);
        setSalePrice(data.salePrice);
        setStatus(data.status);
        setCategory(data.productCategory);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  const getCategoryData = useCallback(() => {
    ProductCategoryServices.getAllProductsCategory()
      .then((res) => {
        setAllCategories(res);
      })
      .catch((err) => console.log(err.message));
  }, []);

  const SelectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#c6a1f8" : null,
        color: isFocused ? "#fff" : "#333333",

        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c6a1f8"
              : "#c6a1f8"
            : undefined,
        },
      };
    },
  };
  const statues = [
    {
      value: "Published",
      label: "Published",
    },
    {
      value: "Draft",
      label: "Draft",
    },
  ];

  useEffect(() => {
    gerDataById();
    getCategoryData();
  }, []);

  let CategoryData = [];
  for (let index = 0; index < allCategories?.length; index++) {
    const element = allCategories[index];
    const obj = {
      label: element.title,
      value: element.title,
    };
    CategoryData.push(obj);
  }

  const handleUpload = () => {
    const formData = new FormData();
    images.forEach((file) => {
      formData.append("images", file);
      setImagesName(file.name);
    });
    setUploading(true);
    // You can use any AJAX library you like
    fetch(process.env.REACT_APP_API_BASE_URL + `/upload/multiple`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setImages([]);
      })
      .catch(() => {})
      .finally(() => {
        setUploading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      productName: title,
      sku: sku,
      description: description,
      pieces: piece,
      price: price,
      salePrice: SalePrice,
      productImage: imagesName,
      productCategory: category,
      status: status,
      quantity: quantity,
    };

    ProductServices.updateProduct(id, body)
      .then((res) => {
        navigate("/all-products");
      })
      .catch((err) => console.error(err.message));
  };

  return (
    <>
      <Helmet>
        <title>Add Product - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Add Product</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Add Product</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Product Title
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="formrow-email-input"
                            >
                              SKU
                            </label>
                            <input
                              required
                              className="form-control"
                              type="text"
                              value={sku}
                              onChange={(e) => setSKU(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="formrow-password-input"
                            >
                              Pices
                            </label>
                            <input
                              required
                              className="form-control"
                              type="Number"
                              min={0}
                              defaultValue={0}
                              value={piece}
                              onChange={(e) => setPiece(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="formrow-password-input"
                            >
                              Quantity
                            </label>
                            <input
                              required
                              className="form-control"
                              type="Number"
                              min={0}
                              defaultValue={0}
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <textarea
                            required
                            className="form-control"
                            type="text"
                            rows={5}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Price
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="Number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Sale Price
                        </label>
                        <div className="col-md-12">
                          <input
                            required
                            className="form-control"
                            type="Number"
                            value={SalePrice}
                            onChange={(e) => setSalePrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-12">
                          <Select
                            value={statues.filter(
                              (statue) => statue.value == status
                            )}
                            options={statues}
                            styles={SelectStyle}
                            onChange={(e) => setStatus(e.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Category
                        </label>
                        <div className="col-md-12">
                          <Select
                            options={CategoryData}
                            value={CategoryData.find(
                              (Category) => Category.value === category
                            )}
                            styles={SelectStyle}
                            onChange={(e) => setCategory(e.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-12 col-form-label"
                        >
                          Images
                        </label>
                        <div className="col-md-12">
                          <ImageUpload
                            fileList={images}
                            setFileList={setImages}
                            handleUpload={handleUpload}
                            uploading={Uploading}
                            setUploading={setUploading}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          // type="submit"
                          onClick={handleUpload}
                          style={{ width: "100%" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProducts;
