import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DataFunction from "../../../Functions/AllFunctions";
import SymptomServices from "../../../services/SymptomServices";
import SymptomCategoryServices from "../../../services/SymptomCategoryServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ImageUpload from "./ImageUpload";
import { notifySuccess } from "../../../utils/toast";
import UploadServices from "../../../services/UploadServices";
import TableLoader from "../../../Reuseable/TableLoader";

const Symptoms = () => {
  const animatedComponents = makeAnimated();

  const [allSymptoms, setAllSymptoms] = useState([]);
  const [symptomsCategory, setSymptomsCategory] = useState([]);
  // State for Create form
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [story, setStory] = useState([]);
  // State for Edit form
  const [nameEdit, setNameEdit] = useState("");
  const [categoryEdit, setCategoryEdit] = useState("");
  const [imageEdit, setImageEdit] = useState(null);
  const [storyEdit, setStoryEdit] = useState([]);

  const [editId, setEditId] = useState(null);

  const StoryImageName = [];
  for (let i = 0; i < story?.length; i++) {
    const element = story[i].name;
    StoryImageName.push(element);
  }
  const StoryImageNameEdit = [];
  for (let i = 0; i < storyEdit?.length; i++) {
    const element = storyEdit[i].name;
    StoryImageNameEdit.push(element);
  }

  const getData = useCallback(() => {
    SymptomServices.getSymptoms()
      .then((res) => setAllSymptoms(res))
      .catch((err) => console.log(err.message));

    SymptomCategoryServices.getAllCategory()
      .then((res) => setSymptomsCategory(res?.data))
      .catch((err) => console.log(err.message));
  }, []);


  let categoryList = [];

  for (let i = 0; i < symptomsCategory?.length; i++) {
    const element = {
      label: symptomsCategory[i].name,
      value: symptomsCategory[i].name,
    };
    categoryList.push(element);
  }

  useEffect(() => {
    getData();
  }, []);

  const deleteCategory = (e, id) => {
    e.preventDefault();
    SymptomServices.deleteSymptom(id).then((res) => getData());
  };

  const addImage = (icon) => {
    var formdata = new FormData();
    formdata.append("file", icon);
    UploadServices.UploadSingle(formdata)
      .then((res) => {
        console.log("Image Uploaded");
      })
      .catch((err) => console.log(err.message));
  };

  const handelCreate = (e) => {
    e.preventDefault();
    const body = {
      name: name,
      category: category?.label,
      image: image?.name,
      story: StoryImageName,
    };
    addImage(image);
    SymptomServices.createSymptoms(body).then((res) => {
      const domEndTime = document.getElementById("symptonIcon");
      domEndTime.value = "";
      setStory([]);
      setName("");
      setImage(null);
      setCategory(null);
      getData();
    });
  };

  const editCategory = (e) => {
    e.preventDefault();
    const body = {
      name: nameEdit,
      category: categoryEdit?.label,
      image: imageEdit?.name,
      story: StoryImageNameEdit,
    };
    const link = document.getElementById("closeModel");

    addImage(imageEdit);
    SymptomServices.updateSymptom(editId, body).then((res) => {
      link.click();
      notifySuccess("Category Updated");
      getData();
      setEditId("");
      setStoryEdit([]);
      setName(null);
      setImageEdit(null);
      setCategoryEdit(null);
    });
  };

  const SelectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#c6a1f8" : null,
        color: isFocused ? "#fff" : "#333333",

        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c6a1f8"
              : "#c6a1f8"
            : undefined,
        },
      };
    },
  };

  const handelEditClick = (e, item) => {
    e.preventDefault();

    const data = [];

    for (let i = 0; i < item?.story?.length; i++) {
      const element = item?.story[i];
      const obj = {
        uid: ++i,
        name: element,
        status: "done",
        url: process.env.REACT_APP_API_BASE_IMAGE_URL + element,
      };
      data.push(obj);
    }

    setCategoryEdit(item?.category);
    setEditId(item?._id);
    setNameEdit(item?.name);
    setStoryEdit(data);
    setCategoryEdit(item?.category);
    setImageEdit(item?.image);
  };

  return (
    <>
      <Helmet>
        <title>Symptoms - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Symptoms</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Symptoms</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={(e) => handelCreate(e)}>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="formrow-firstname-input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Stories
                        </label>
                        <ImageUpload fileList={story} setFileList={setStory} />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Symptom Icon
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="symptonIcon"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="formrow-firstname-input"
                        >
                          Category
                        </label>
                        <Select
                          options={categoryList}
                          components={animatedComponents}
                          styles={SelectStyle}
                          value={category}
                          onChange={(e) => setCategory(e)}
                        />
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit form
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-8">
                <div className="card">
                  <div className="card-body">
                    {allSymptoms === undefined ||
                    allSymptoms === null ||
                    allSymptoms?.length === 0 ? (
                      <TableLoader />
                    ) : (
                      <>
                        <div className="table-responsive">
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Sr#</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Image</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allSymptoms &&
                                allSymptoms.map((el, index) => (
                                  <tr key={el?._id}>
                                    <td>{++index}</td>
                                    <td>{el?.name}</td>
                                    <td>{el?.category}</td>
                                    <td>
                                      {DataFunction.imageCheck(el?.image)}
                                    </td>
                                    <td>
                                      <i
                                        className="mdi mdi-trash-can-outline iconsize"
                                        onClick={(e) =>
                                          deleteCategory(e, el._id)
                                        }
                                      />
                                      <i
                                        className="mdi mdi-pencil-box-outline iconsize"
                                        onClick={(e) => handelEditClick(e, el)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#myModal"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <>
                          <div
                            id="myModal"
                            className="modal fade"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="myModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title" id="myModalLabel">
                                    Symptoms Edit
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    id="closeModel"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <form onSubmit={(e) => editCategory(e)}>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="formrow-firstname-input"
                                      >
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-input"
                                        value={nameEdit}
                                        onChange={(e) =>
                                          setNameEdit(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="formrow-firstname-input"
                                      >
                                        Category
                                      </label>
                                      <Select
                                        options={categoryList}
                                        components={animatedComponents}
                                        // value={categoryList.find(
                                        //   (el) => el.value === categoryEdit
                                        // )}
                                        value={categoryList.find(
                                          (Category) =>
                                            Category.value === categoryEdit
                                        )}
                                        styles={SelectStyle}
                                        onChange={(e) => setCategoryEdit(e)}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="formrow-firstname-input"
                                      >
                                        Stories
                                      </label>
                                      <ImageUpload
                                        fileList={storyEdit}
                                        setFileList={setStoryEdit}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <div className="row">
                                        <div className="col-8">
                                          <label
                                            className="form-label"
                                            for="formrow-firstname-input"
                                          >
                                            Symptom Icon
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control"
                                            id="formrow-firstname-input"
                                            onChange={(e) =>
                                              setImageEdit(e.target.files[0])
                                            }
                                          />
                                        </div>
                                        <div className="col-4">
                                          <img
                                            src={
                                              process.env
                                                .REACT_APP_API_BASE_IMAGE_URL +
                                              imageEdit
                                            }
                                            alt=""
                                            crossOrigin="anonymous"
                                            style={{ width: "100px" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={(e) => editCategory(e)}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                              {/* /.modal-content */}
                            </div>
                            {/* /.modal-dialog */}
                          </div>
                        </>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Symptoms;
