import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import csvDownload from "json-to-csv-export";
import BarChart from "../Home/BarChart";
import ReportsServices from "../../services/ReportsServices";
import moment from "moment";
import TableLoader from "../../Reuseable/TableLoader";

function CurrentMonthOrder() {
  const [reportData, setReportData] = useState([]);

  const { RangePicker } = DatePicker;

  const [startDateClick, setStartDateClick] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDateClick, setEndDateClick] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const getCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.currentMonthDailyOrder(startDateClick, endDateClick)
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => console.log(err.message));
  };

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  useEffect(() => {
    getCurrentReport(startDateClick, endDateClick);
  }, [endDateClick]);

  const dataToConvert = {
    data: reportData[2]?.table,
    filename: "Current_Month_Orders",
    delimiter: ",",
    headers: ["Date", "Orders"],
  };
  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Current Month Orders Trend</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div className="float-end">
                  <div className=" mb-3">
                    <RangePicker
                      allowClear="true"
                      onCalendarChange={handleCalendarChange}
                    />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div> */}
            {reportData.length === 0 ? (
              <TableLoader />
            ) : (
              <>
                {" "}
                <div className="row">
                  <div className="col-md-12">
                    <div className="card" style={{ borderRadius: "15px" }}>
                      <div className="card-body">
                        {reportData.length === 0 ? null : (
                          <>
                            <BarChart reportData={reportData} />
                          </>
                        )}

                        {/* <LineChart OrderData={OrderData} /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="float-end pb-2">
                            <Link
                              onClick={() => csvDownload(dataToConvert)}
                              to="#"
                              className=""
                            >
                              <p style={{ textDecorationLine: "underline" }}>
                                Export csv
                              </p>
                            </Link>
                          </div>
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Total Orders</th>
                                <th>Pending</th>
                                <th>Processing</th>
                                <th>Delivered</th>
                                <th>Cancelled</th>
                                <th>Returned</th>
                                <th>Dispatched</th>
                              </tr>
                            </thead>

                            <tbody>
                              {reportData[2]?.table?.map((item,i) => (
                                <>
                                  <tr key={i}>
                                    <td>{item.date}</td>
                                    <td>{item.orders}</td>
                                    <td>{item.Pending || 0}</td>
                                    <td>{item.Processing || 0}</td>
                                    <td>{item.Delivered || 0}</td>
                                    <td>{item.Cancelled || 0}</td>
                                    <td>{item.Returned || 0}</td>
                                    <td>{item.Dispatched || 0}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentMonthOrder;
