import requests from "./httpService";

const ReviewsServices = {
  listAllReviews() {
    return requests.get("/reviewsRoutes/listAllReviews");
  },
  changeReviewStatus(id) {
    return requests.get(`/reviewsRoutes/status/${id}`);
  },
};

export default ReviewsServices;
