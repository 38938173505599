import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";

function LineChart({ OrderData }) {
  // create an array to hold the numbers of orders for the last 30 days
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const filteredOrders = OrderData.filter(OrderData => new Date(OrderData.createdAt) > thirtyDaysAgo);


  const ordersByDate = filteredOrders.reduce((acc, OrderData) => {
    const date = new Date(OrderData.createdAt).toISOString().slice(0, 10);

    if (acc[date]) {
      acc[date]++;
    } else {
      acc[date] = 1;
    }

    return acc;
  }, {});



  // console.log("ordersLast30Days", ordersLast30Days);

  const options = {
    chart: {
      id: "line-chart",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 2000,
        },
      },
    },
    xaxis: {
      categories: Object.keys(ordersByDate)
    },
    yaxis: {
      title: {
        text: "Total Orders (Last 30 Days)",
      },
    },
    colors: ["#6c5c8d"],
  };

  const series = [
    {
      name: "Today's Orders",
      data: Object.values(ordersByDate)
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
}

export default LineChart;
