import React, { useLayoutEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import Dashboard from "./Pages/Home/Dashboard";
import PrivateComponent from "./Private/PrivateComponent";
import LockScreen from "./Pages/Auth/LockScreen";
import ChangePass from "./Pages/Auth/ChangePass";
import Posts from "./Pages/Blog/Posts/Posts";
import Categories from "./Pages/Blog/Categories/Categories";
import CreatePost from "./Pages/Blog/Posts/CreatePost";
import EditPost from "./Pages/Blog/Posts/EditPost";
import Customers from "./Pages/Ecommerce/Customers/Customers";
import CustomerDetail from "./Pages/Ecommerce/Customers/CustomerDetail";
import Products from "./Pages/Ecommerce/Products/Products";
import Orders from "./Pages/Ecommerce/Orders/Orders";
import ProductsOrder from "./Pages/Ecommerce/Products/ProductsOrder";
import OrderDetail from "./Pages/Ecommerce/Orders/OrderDetail";
import Symptoms from "./Pages/Health/Symptoms/Symptoms";
import SymptomsCategories from "./Pages/Health/Categories/SymptomsCategories";
import ProductCategories from "./Pages/Ecommerce/ProductCategories/ProductCategories";
import Subscriptions from "./Pages/Ecommerce/Subscriptions/Subscriptions";
import Reviews from "./Pages/Ecommerce/Reviews/Reviews";
import AddProducts from "./Pages/Ecommerce/Products/AddProducts";
import ProductDetail from "./Pages/Ecommerce/Products/ProductDetail";
import EditProducts from "./Pages/Ecommerce/Products/EditProduct";
import ProductCoupons from "./Pages/Ecommerce/Coupon/ProductCoupons";
import Cities from "./Pages/Ecommerce/Cities/Cities";
import MontlyReportYear from "./Pages/Reports/MontlyReportYear";
import CurrentMonthOrder from "./Pages/Reports/CurrentMonthOrder";
import MontlySubTrend from "./Pages/Reports/MontlySubTrend";
import TotalOrders from "./Pages/Reports/TotalOrders";
import SkuTrend from "./Pages/Reports/SkuTrend";
import NewReccutingOrders from "./Pages/Reports/NewReccutingOrders";
import TotalCitiesOrders from "./Pages/Reports/TotalCitiesOrders";
import Promotion from "./Pages/Promotion";
import UserSymtoms from "./Pages/Reports/UserSymtoms";
import RejectReason from "./Pages/Ecommerce/RejectReason/RejectReason";
import CustomersWithOrder from "./Pages/Ecommerce/Customers/CustomersWithOrder";
import CustomersWithoutOrder from "./Pages/Ecommerce/Customers/CustomersWithoutOrder";
import PromoCodeReport from "./Pages/Reports/PromoCodeReport";
import DailyActiveUsers from "./Pages/Reports/DailyActiveUsers";
import CustomerRetentionAndAcquisition from "./Pages/Reports/CustomerRetentionAndAcquisition";
import ReportDashboard from "./Pages/Reports/ReportDashboard";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/lock_screen" element={<LockScreen />} />
        <Route path="/change_password" element={<ChangePass />} />
        <Route element={<PrivateComponent />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/post/all-posts" element={<Posts />} />
          <Route path="/post/create" element={<CreatePost />} />
          <Route path="/post/edit/:id" element={<EditPost />} />
          <Route path="/post/categories" element={<Categories />} />
          {/* <Route path="/all-customers" element={<Customers />} /> */}
          <Route
            path="/all-customers-orders"
            element={<CustomersWithOrder />}
          />
          <Route path="/all-customers" element={<CustomersWithoutOrder />} />
          <Route path="/customer/:id" element={<CustomerDetail />} />
          {/* Products */}
          <Route path="/all-products" element={<Products />} />
          <Route path="/product/orders" element={<Orders />} />
          <Route path="/product/rejection" element={<RejectReason />} />
          <Route path="/product/add" element={<AddProducts />} />
          <Route path="/product/detail/:id" element={<ProductDetail />} />
          <Route path="/product/edit/:id" element={<EditProducts />} />
          <Route path="/product_orders" element={<ProductsOrder />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/coupons" element={<ProductCoupons />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/product/categories" element={<ProductCategories />} />
          <Route path="/product/orders/detail/:id" element={<OrderDetail />} />
          <Route path="/symptoms" element={<Symptoms />} />
          <Route path="/symptoms/categories" element={<SymptomsCategories />} />
          <Route path="/cities" element={<Cities />} />
          <Route path="/promotion" element={<Promotion />} />
          {/* Reports */}
          <Route path="/report/dashboard" element={<ReportDashboard />} />
          <Route path="/report/montly" element={<MontlyReportYear />} />
          <Route path="/report/currentMonth" element={<CurrentMonthOrder />} />
          <Route
            path="/report/customer-retention-and-acquisition-report"
            element={<CustomerRetentionAndAcquisition />}
          />
          <Route
            path="/report/montlySubscription"
            element={<MontlySubTrend />}
          />
          <Route path="/report/totalOrders" element={<TotalOrders />} />
          <Route
            path="/report/totalCitiesOrders"
            element={<TotalCitiesOrders />}
          />
          <Route path="/report/skuTrends" element={<SkuTrend />} />
          <Route path="/report/topUsedSymtoms" element={<UserSymtoms />} />
          <Route path="/report/dau" element={<DailyActiveUsers />} />
          <Route
            path="/report/newReoccuring"
            element={<NewReccutingOrders />}
          />
          <Route path="/report/promocode" element={<PromoCodeReport />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
