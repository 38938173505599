import requests from "./httpService";

const CityServices = {
  createCity(body) {
    return requests.post(`/city/create`, body);
  },
  getAllCities() {
    return requests.get(`/city/`);
  },
  changeCityStatus(id) {
    return requests.get(`/city/status/${id}`);
  },
};

export default CityServices;
