import requests from "./httpService";

const CouponServices = {
  GetAllCoupon() {
    return requests.get("/coupons");
  },
  GetCouponById(id) {
    return requests.get(`/coupons/${id}`);
  },
  UpdateCoupon(id, body) {
    return requests.put(`/coupons/${id}`, body);
  },
  DeleteCoupon(id) {
    return requests.delete(`/coupons/${id}`);
  },
  StatusChange(id) {
    return requests.get(`/coupons/status/${id}`);
  },
  MultiChange(id) {
    return requests.get(`/coupons/multi/${id}`);
  },
  AddCoupon(body) {
    return requests.post("/coupons/add", body);
  },
};

export default CouponServices;
