import requests from "./httpService";

const ReportDashboardServices = {
  allPercentage(body) {
    return requests.get(`/customers/allpercentage`, body);
  },
  inactvepercentage(body) {
    return requests.get(`/customers/inactvepercentage`,body);
  },
};

export default ReportDashboardServices;
