import requests from "./httpService";

const RejectionService = {
  getRejection(body) {
    return requests.get(`/rejection/all`, body);
  },
  createRejection(body) {
    return requests.post(`/rejection/create`, body);
  },
  updateRejection(id, body) {
    return requests.put(`/rejection/${id}`, body);
  },
  deleteReasons(id) {
    return requests.delete(`/rejection/${id}`);
  },
};

export default RejectionService;
