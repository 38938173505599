import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PromotionSetvices from "../../services/PromotionSetvices";
import UploadServices from "../../services/UploadServices";
import axios from "axios";

const Promotion = () => {
  const [allPromotions, setAllPromotion] = useState(null);

  console.log("allPromotions", allPromotions);

  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);

  const getData = useCallback(() => {
    PromotionSetvices.getAllPromotions()
      .then((res) => {
        console.log(res);
        setAllPromotion(res);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const imageUplaod = async (image) => {};

  console.log("image", image);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      image: image?.name,
    };

    PromotionSetvices.createPromotions(data)
      .then((res) => {
        console.log(res);
        getData();

        // imageUplaod(image);
        const formData = new FormData();
        formData.append("file", image);

        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("File uploaded successfully:", response.data);
            // Handle success, e.g., show a success message to the user
            setName(null);
            setImage(null);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            // Handle error, e.g., show an error message to the user
            setName(null);
            setImage(null);
          });

        // UploadServices.UploadSingle(image)
        //   .then((res) => {
        //     console.log("upload sussceefull");
        //     setName(null);
        //     setImage(null);
        //   })
        //   .catch((err) => {
        //     console.log("Upload Failed");
        //     setName(null);
        //     setImage(null);
        //   });
      })
      .catch((err) => {
        console.log(err);
        setName(null);
        setImage(null);
      });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();

    PromotionSetvices.deletePromotions(id).then((res) => {
      getData();
    });
  };

  return (
    <>
      <Helmet>
        <title>Promotion - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    App Splasher &nbsp;
                    <small className="text-muted fw-normal">
                      (Only one image should be with active status at a time as
                      the only single image will appear in app.)
                    </small>
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">App Splasher</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="mb-3">
                        <label
                          htmlFor="promotionName"
                          className="col-md-12 col-form-label"
                        >
                          Promotion Name
                        </label>
                        <div className="col-md-12">
                          <input
                            id="promotionName"
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="promotionImage"
                          className="col-md-12 col-form-label"
                        >
                          Promotion Image
                        </label>
                        <div className="col-md-12">
                          <input
                            id="promotionImage"
                            type="file"
                            className="form-control"
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                          <small className="text-muted fw-normal">
                            We recommend to keep image size less than 0.8mb
                          </small>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Sr#</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>IsActive</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allPromotions &&
                            allPromotions.map((el, index) => (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{el.name}</td>
                                <td>
                                  <img
                                    src={
                                      process.env.REACT_APP_API_BASE_IMAGE_URL +
                                      el?.image
                                    }
                                    alt={el?.image}
                                    crossOrigin="anonymous"
                                    style={{
                                      width: 250,
                                      // height: 150,
                                      objectFit: "contain",
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className="form-check form-switch form-switch-md mb-3">
                                    <input
                                      // onChange={() => changeStatus(el._id)}
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customSwitchsizemd"
                                      defaultChecked={el.isActive}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <i
                                    className="mdi mdi-trash-can-outline iconsize"
                                    onClick={(e) => handleDelete(e, el._id)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promotion;
