import requests from "./httpService";

const SymptomServices = {
  createSymptoms(body) {
    return requests.post(`/symptoms/`, body);
  },
  getSymptoms(body) {
    return requests.get(`/symptoms/`, body);
  },
  getSymptomById(id) {
    return requests.get(`/symptoms/${id}`);
  },
  updateSymptom(id, body) {
    return requests.put(`/symptoms/${id}`, body);
  },
  deleteSymptom(id) {
    return requests.delete(`/symptoms/${id}`);
  },
};

export default SymptomServices;
