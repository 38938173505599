import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import csvDownload from "json-to-csv-export";
import BarChart from "../Home/BarChart";
import ReportsServices from "../../services/ReportsServices";
import moment from "moment";

function TotalCitiesOrders() {
  const [reportData, setReportData] = useState([]);

  const { RangePicker } = DatePicker;
  const currentDate = new Date();
  const [startDateClick, setStartDateClick] = useState(
    // new Date(currentDate.getFullYear(), 0, 1).toISOString()
    moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
  );
  const [endDateClick, setEndDateClick] = useState(
    // new Date(currentDate.getFullYear(), 11, 31).toISOString() // moment().endOf("month").format("YYYY-MM-DD")
    moment().endOf("year").format("YYYY-MM-DD")
  );

  const getCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.TopTenOrderedCities(startDateClick, endDateClick)
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => console.log(err.message));
  };

  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };

  useEffect(() => {
    getCurrentReport(startDateClick, endDateClick);
  }, [endDateClick]);

  const dataToConvert = {
    data: reportData[2]?.table,
    filename: "Top_10_Cities_Orders",
    delimiter: ",",
    headers: ["City", "Order"],
  };
  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Orders Trends of Top 10 Cities</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div className="float-end">
                  <div className=" mb-3">
                    <RangePicker
                      allowClear="true"
                      onCalendarChange={handleCalendarChange}
                    />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <BarChart reportData={reportData} />
                    {/* <LineChart OrderData={OrderData} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="float-end pb-2">
                        <Link
                          onClick={() => csvDownload(dataToConvert)}
                          to="#"
                          className=""
                        >
                          <p style={{ textDecorationLine: "underline" }}>
                            Export csv
                          </p>
                        </Link>
                      </div>
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>City</th>
                            <th>Orders</th>
                          </tr>
                        </thead>

                        <tbody>
                          {reportData[2]?.table?.map((item,i) => (
                            <>
                              <tr key={i}>
                                <td>{item.month}</td>
                                <td>{item.orders}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalCitiesOrders;
