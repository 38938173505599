import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CityServices from "../../../services/CityServices";
import TableLoader from "../../../Reuseable/TableLoader";

const Cities = () => {
  const [allCities, setAllCities] = useState([]);

  //   Create
  const [cityName, setCityName] = useState("");
  const [cityTcsValue, setCityTcsValue] = useState("");
  const [citySwiftValue, setCitySwiftValue] = useState("");

  const getData = useCallback(() => {
    CityServices.getAllCities()
      .then((res) => setAllCities(res))
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const changeStatus = (id) => {
    CityServices.changeCityStatus(id).then((res) => getData());
  };

  const onCreateCity = (e) => {
    e.preventDefault();
    const body = {
      label: cityName,
      value: cityName,
      swiftvalue: citySwiftValue,
      tcsvalue: cityTcsValue,
    };
    CityServices.createCity(body)
      .then((res) => {
        let link = document.getElementById("CLoceModalCreate");
        getData();
        link.click();
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <>
      <Helmet>
        <title>Cities - Emberace</title>
      </Helmet>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Cities</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Cities</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-8">
                        <div className="float-end">
                          <div className=" mb-3">
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm waves-effect waves-light"
                              data-bs-toggle="modal"
                              data-bs-target="#createCityModal"
                            >
                              <i
                                className="mdi mdi-plus"
                                style={{ marginRight: "5px" }}
                              />
                              Add Cities
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      {allCities === undefined ||
                      allCities === null ||
                      allCities?.length === 0 ? (
                        <>
                          <TableLoader />
                        </>
                      ) : (
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Label</th>
                              <th>TCS Code</th>
                              <th>Swift Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              {allCities &&
                                allCities.map((el, index) => (
                                  <tr key={el._id}>
                                    <th scope="row">{++index}</th>
                                    <td>{el?.label}</td>
                                    <td>{el?.tcsvalue}</td>
                                    <td>{el?.swiftvalue}</td>
                                    <td>
                                      <div className="form-check form-switch form-switch-md mb-3">
                                        <input
                                          onChange={() => changeStatus(el._id)}
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customSwitchsizemd"
                                          defaultChecked={el.isActive}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="createCityModal"
              className="modal fade"
              tabIndex={-1}
              aria-labelledby="myModalLabel"
              aria-hidden="true"
              style={{ display: "none" }}
            >
              <div className="modal-dialog">
                <form
                  className="modal-content"
                  onSubmit={(e) => onCreateCity(e)}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="myModalLabel">
                      Add City
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      id="CLoceModalCreate"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        City Name
                      </label>
                      <div className="col-md-12">
                        <input
                          required
                          className="form-control"
                          type="text"
                          value={cityName}
                          onChange={(e) => setCityName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        TCS City Code
                      </label>
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="text"
                          value={cityTcsValue}
                          onChange={(e) => setCityTcsValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-12 col-form-label"
                      >
                        Swift City Code
                      </label>
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          type="text"
                          value={citySwiftValue}
                          onChange={(e) => setCitySwiftValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light waves-effect"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Save changes
                    </button>
                  </div>
                </form>
                {/* /.modal-content */}
              </div>
              {/* /.modal-dialog */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cities;
