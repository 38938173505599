import React, { useCallback, useEffect, useState } from "react";
import BarChart from "./BarChart";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import {
  BsCart,
  BsCartCheck,
  BsWallet,
  BsPeople,
  BsBell,
  BsClockHistory,
} from "react-icons/bs";
import DashboardServices from "../../services/DashboardServices";
import moment from "moment";
import LineChart from "./LineChart";
import { commaSperate } from "../../Functions/Helper";

const Dashboard = () => {
  const navigate = useNavigate();

  const [totalData, setTotalData] = useState(null);
  const [OrderData, setOrderData] = useState([]);

  const getData = useCallback(() => {
    DashboardServices.getTotalData()
      .then((res) => {
        setTotalData(res);
        setOrderData(res.OrderData);
      })
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Helmet>
        <title>Dashboard - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4">
                <div className="card bg-primary cardGradient">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-sm-8">
                        <p className="tracking-in-expand text-white font-size-18">
                          <b className="fontset" style={{ fontSize: 24 }}>
                            Welcome to Embrace Dashboard
                          </b>
                          <br />
                          It shows what's happening in your account today for
                          better outreach
                        </p>
                        <div className="mt-4">
                          <Link
                            to="/product/orders"
                            className="tracking-in-expand btn cardButton waves-effect waves-light"
                          >
                            See All Orders
                          </Link>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mt-4 mt-sm-0">
                          <img
                            src="/assets/images/cartoon.svg"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsWallet />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            Rs
                            <span data-plugin="counterup">
                              {commaSperate(totalData?.totalRevenu)}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Total Revenue
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsPeople />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {totalData?.totalCustomers}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Total Customers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsBell />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {totalData?.totalSubscription}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Total Subscriptions
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsCart />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {totalData?.totalOrders}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Total Orders
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsClockHistory />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {totalData?.totalOrdersPending}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Pending Orders
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card smallCard">
                      <div className="card-body">
                        <div className="cardicon float-end mt-2">
                          <BsCartCheck />
                        </div>
                        <div>
                          <h4 className="tracking-in-expand mb-1 mt-1">
                            <span data-plugin="counterup">
                              {totalData?.totalOrdersDelivered}
                            </span>
                          </h4>
                          <p className="tracking-in-expand text-muted mb-0">
                            Delivered Orders
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <LineChart OrderData={OrderData} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Top Products</h4>
                      <div data-simplebar="init" style={{ maxHeight: 336 }}>
                        <div
                          className="simplebar-wrapper"
                          style={{ margin: 0 }}
                        >
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer" />
                          </div>
                          <div className="simplebar-mask">
                            <div
                              className="simplebar-offset"
                              style={{ right: "-17px", bottom: 0 }}
                            >
                              <div
                                className="simplebar-content-wrapper"
                                style={{
                                  height: "auto",
                                  overflow: "hidden scroll",
                                }}
                              >
                                <div
                                  className="simplebar-content"
                                  style={{ padding: 0 }}
                                >
                                  <div className="table-responsive">
                                    <table className="table table-borderless table-centered table-nowrap">
                                      <tbody>
                                        {totalData?.topFiveProductsData?.map(
                                          (els, i) => (
                                            <tr key={i}>
                                              <td>
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_API_BASE_IMAGE_URL +
                                                    els?.productImage[0]
                                                  }
                                                  className="avatar-xs rounded-circle"
                                                  alt=""
                                                  crossOrigin="anonymous"
                                                  onError={(e) =>
                                                    (e.target.src =
                                                      "/assets/images/noimage.jpg")
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <h6 className="font-size-15 mb-1 fw-normal">
                                                  {els.productName}
                                                </h6>
                                                <p className="text-muted font-size-13 mb-0">
                                                  Total Orders:{" "}
                                                  {els.productCount}
                                                </p>
                                              </td>
                                              <td>
                                                Rs
                                                {commaSperate(els.productTotal)}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  {/* enbd table-responsive*/}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="simplebar-placeholder"
                            style={{ width: "auto", height: 482 }}
                          />
                        </div>
                        <div
                          className="simplebar-track simplebar-horizontal"
                          style={{ visibility: "hidden" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              transform: "translate3d(0px, 0px, 0px)",
                              display: "none",
                            }}
                          />
                        </div>
                        <div
                          className="simplebar-track simplebar-vertical"
                          style={{ visibility: "visible" }}
                        >
                          <div
                            className="simplebar-scrollbar"
                            style={{
                              height: 234,
                              transform: "translate3d(0px, 102px, 0px)",
                              display: "block",
                            }}
                          />
                        </div>
                      </div>
                      {/* data-sidebar*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {totalData?.lastFiveOrders ? (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title mb-4">Latest Orders</h4>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th style={{ width: 20 }}>
                                <div className="form-check font-size-16">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheck1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customCheck1"
                                  >
                                    &nbsp;
                                  </label>
                                </div>
                              </th>
                              <th>Order ID</th>
                              <th>Billing Name</th>
                              <th>Date</th>
                              <th>Total</th>
                              <th>Order Type</th>
                              <th>Status</th>
                              <th>View Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {totalData?.lastFiveOrders &&
                              totalData?.lastFiveOrders?.map((el, index) => (
                                <tr key={el._id}>
                                  <td>
                                    <div className="form-check font-size-16">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customCheck2"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="customCheck2"
                                      >
                                        &nbsp;
                                      </label>
                                    </div>
                                  </td>
                                  <td className="text-body fw-bold">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/product/orders/detail/${el?.orderId}`
                                        )
                                      }
                                    >
                                      #{el?.orderId}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        navigate(`/customer/${el?.userId}`)
                                      }
                                    >
                                      {el?.name}
                                    </span>
                                  </td>
                                  <td>
                                    {moment(el.createdAt).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    Rs
                                    {commaSperate(
                                      el?.cart?.reduce(
                                        (a, c) => a + c.price * c.quantity,
                                        0
                                      )
                                    )}
                                  </td>
                                  <td>{el?.subscriptionType}</td>
                                  <td>{el?.status}</td>
                                  <td>
                                    <span
                                      onClick={() =>
                                        navigate(
                                          `/product/orders/detail/${el?.orderId}`
                                        )
                                      }
                                      className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                    >
                                      View Details
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {/* end table-responsive */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
