import requests from "./httpService";

const OrderServices = {
  getAllOrders() {
    return requests.get(`/orders`);
  },

  getRecentOrders({
    page = 1,
    limit = 8,
    startDate = "1:00",
    endDate = "23:59",
  }) {
    return requests.get(
      `/orders/recent?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    );
  },

  getOrderAndSalesChart({ startDate = "1:00", endDate = "23:59" }) {
    return requests.get(
      `/orders/sales/chart?startDate=${startDate}&endDate=${endDate}`
    );
  },

  getBestSellerProductChart() {
    return requests.get("/orders/best-seller/chart");
  },

  getDashboardOrdersData({ page = 1, limit = 8 }) {
    return requests.get(`/orders/dashboard?page=${page}&limit=${limit}`);
  },

  getOrderByUser(id, body) {
    return requests.get(`/orders/user/${id}`, body);
  },

  getOrderById(id, body) {
    return requests.get(`/orders/${id}`, body);
  },

  updateOrder(id, body, headers) {
    return requests.put(`/orders/${id}`, body, headers);
  },

  deleteOrder(id) {
    return requests.delete(`/orders/${id}`);
  },
  submitOrder(body) {
    return requests.post(`/user/orders/add`, body);
  },
  checkSwyftStatus(orderID,trackingNumber,body) {
    return requests.post(`/orders/updateSwyftOrderStatus/${orderID}/${trackingNumber}`, body);
  },
  checkTCSStatus(orderID,trackingNumber,body) {
    return requests.post(`/orders/updateTcsOrderStatus/${orderID}/${trackingNumber}`, body);
  },
  checkAllTCSStatus(body) {
    return requests.put(`/orders/updateAllTcsOrderStatus`, body);
  },
  subscriptionOrder(body) {
    return requests.post(`/app/create_subscription`, body);
    // return requests.post(`/subscription/add`, body);
  },
  statusUpdate(id, body) {
    return requests.put(`/orders/status/${id}`, body);
  },
  updateParcelId(id, body) {
    return requests.put(`/orders/parcel/${id}`, body);
  },
  rejectionReason(id, body) {
    return requests.put(`/orders/rejection/${id}`, body);
  },
  updateOrderTrackingId(orderId,body) {
    return requests.post(`/orders/updateSwyftOrderTracking/${orderId}`, body);
  },
  updateAllSwiftStatus(body) {
    return requests.get(`/orders/updateSwyftStatus`, body);
  },
};

export default OrderServices;
