import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import csvDownload from "json-to-csv-export";
import ReportsServices from "../../services/ReportsServices";
import LineChart from "../Home/LineChart";
import PieChart from "../Home/PieChart";
import moment from "moment";
import { DatePicker } from "antd";

function SkuTrend() {
  const { RangePicker } = DatePicker;
  const [reportData, setReportData] = useState([]);
  const [OrderData, setOrderData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [startDateClick, setStartDateClick] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDateClick, setEndDateClick] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const getCurrentReport = (startDateClick, endDateClick) => {
    ReportsServices.SkuTrends(startDateClick, endDateClick)
      .then((res) => {
        setReportData(res);
      })
      .catch((err) => console.log(err.message));
  };
  const handleCalendarChange = (value, dateString) => {
    setStartDateClick(dateString[0]);
    setEndDateClick(dateString[1]);
  };
  useEffect(() => {
    getCurrentReport(startDateClick, endDateClick);
  }, [endDateClick]);

  const dataToConvert = {
    data: reportData[2]?.table,
    filename: "Sku_Trends",
    delimiter: ",",
    headers: ["Sku", "Product Name","Order Counts"],
  };
  return (
    <>
      <Helmet>
        <title>Reports - Emberace</title>
      </Helmet>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">SKUs Trends</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Reports</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <div className="float-end">
                  <div className=" mb-3">
                    <RangePicker
                      allowClear="true"
                      onCalendarChange={handleCalendarChange}
                    />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ borderRadius: "15px" }}>
                  <div className="card-body">
                    {reportData.length===0?'':<><PieChart reportData={reportData} /></>}
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                    <div className="float-end pb-2">
                        <Link
                          onClick={() => csvDownload(dataToConvert)}
                          to="#"
                          className=""
                        >
                          <p style={{ textDecorationLine: "underline" }}>
                            Export csv
                          </p>
                        </Link>
                      </div>
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            
                            <th>Sku</th>
                            <th>Product Name</th>
                            <th>Order Counts</th>
                            
                          </tr>
                        </thead>

                        <tbody>
                        { reportData[2]?.table?.map(item => (<>
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.category}</td>
                            <td>{item.orders}</td>
                            
                          </tr>
                         
                         </>))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkuTrend;
